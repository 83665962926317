import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ortholp2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ortholp2.component.html',
  styleUrl: './ortholp2.component.scss'
})
export class Ortholp2Component {

  showDropdown = false;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  showDropdown2 = false;

  toggleDropdown2() {
    this.showDropdown2 = !this.showDropdown2;
  }

}
