import { CommonModule } from '@angular/common';




import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core"
import {FormControl, Validators} from "@angular/forms"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrudService } from '../../Services/crud.service';




@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [  CommonModule




],
providers: [],

  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {


  ngOnInit(): void {}


}
