

<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Comprehensive Orthodontic Care
        </span>
      </div>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Free Braces Consultation
      </h1>
      <p class='text-xl leading-loose text-purple-800 max-w-2xl mt-3 text-center lg:text-left'>
        Come in for a free consultation and explore our different treatments, including Braces, Clear Braces, and Invisalign, each tailored to fit your individual needs. We offer 0% interest financing to help make orthodontics affordable and keep your monthly payments low.

      </p>
      <ul>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Free Orthodontic Consultation</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Go over treatment options (Invisalign or Braces).</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Explore payment plans that fit your budget.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Get a beautiful smile</span>
        </li>
      </ul>
      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>
        <p class="text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mx-auto mt-14">
          Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
        </p>
      </div>
    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/ortho_2.webp" alt="Orthodontic Treatment">
      </div>
    </div>
  </div>
  <div class='mt-10 lg:mt-12 text-center mx-auto max-w-xl lg:max-w-screen-xl'>
    <a href="tel:9095315179" class='inline-flex items-center justify-center px-8 py-3 mt-6 text-lg font-medium text-black bg-orange-400 rounded-full hover:bg-yellow-500 hover:text-black duration-300 ease-in-out sm:mt-0 sm:ml-6'>
      Call For Your Free Consultation
    </a>
  </div>

</section>

<section loading="lazy" class='px-4 pb-5 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-24'>
  <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Benefits of Orthodontics
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Improved Oral Health: Correct alignment reduces the risk of dental issues.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Enhanced Aesthetics: Straight teeth contribute to a more attractive smile.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Customized Solutions: Tailored treatments for individual dental needs.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Increased Confidence: A beautiful smile boosts self-esteem and social interactions.</span>
        </li>

      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-purple-800'>
      Discover the full range of orthodontic treatments at Ketcherside Orthodontics in Corona. Embrace the journey to a healthier, more beautiful smile today!
    </p>
  </div>

</section>


@defer (prefetch on idle) {
<section loading="lazy" class=' bg-gradient-to-b from-purple-100 to-yellow-50  '>

  <div class='px-4 mx-auto lg:max-w-screen-2xl sm:px-6'>

    <div class='flex flex-col items-center justify-center'>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Reviews
      </h1>

    </div>

    <div class='grid gap-8 mt-12 md:gap-8 sm:gap-6 md:mt-14 lg:mt-16 xl:mt-20 2xl:mt-24 xl:grid-cols-4 sm:grid-cols-2 2xl:gap-12 lg:gap-6'>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-yellow-200 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img loading="lazy" src='../../../../assets/images1/d.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt='Testimonial 01'>
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Devyn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “I had my braces done by Dr. Ketcherside and he did an amazing job. Him and his staff are always so welcoming, kind and very helpful. I highly recommend going to Dr. Ketcherside. You will not be disappointed."        </p>
      </blockquote>

      <blockquote class='px-8 py-8  bg-purple-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
        <img loading="lazy" src='../../../../assets/images1/a.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full  ' alt="Testimonial 02">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Autumn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "Friendly staff, the office is really nice and clean. This was my first visit and they explained all the procedures clearly and made me feel comfortable."        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-rose-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
        <img src='../../../../assets/images1/g.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 03">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          G J
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “This office is the best! The front desk staff are amazing and friendly!  Dr Ketcherside is very kind and professional. I highly recommend this office to anyone looking for dental care and to be treated like royalty while you are there.”        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-blue-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img  loading="lazy" src='../../../../assets/images1/k.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 04">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Katie
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "We have been coming to Dr. Ketcherside in Corona for over 7yrs. Myself and my 3 children has had amazing work done on our teeth by the Dr. The Dr & Staff are amazing!!!  Super welcoming and friendly. Clean office and hands down give out the best cookies."        </p>
      </blockquote>

    </div>

  </div>

</section>


<section class="bg-yellow-50 h-auto pb-10 pt-20">

  <div class='flex flex-col justify-center align-middle max-w-3xl mx-auto lg:w-1/2 w-3/4 lg:mt-0 lg:max-w-none lg:col-span-6'>
    <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Send us a message
          </h3>
          <p class='text-purple-800 mt-0.5 text-opacity-90'>
            We'll get back to you as soon as possible
          </p>
        </div>

        <div class="pt-7">
          </div>



          <form action="https://formspree.io/f/xoqolkjo" method="POST" class="flex flex-col space-y-4 bg-purple-50 p-4 shadow-2xl rounded-lg">

          

            <label class="block">
              <span class="text-gray-700">Your name:</span>
              <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
            </label>
  
            <!-- Email Input -->
           
  
            <!-- Phone Number Input -->
            <label class="block">
              <span class="text-gray-700">Phone number:</span>
              <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="123-456-7890">
            </label>
  
            <!-- Best Time to Contact Input -->
         
  
            <!-- Preference for Text or Call -->
            <label class="block">
              <span class="text-gray-700">Prefer Contact Method:</span>
              <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                <option value="text">Text</option>
                <option value="call">Call</option>
              </select>
            </label>
  
            <!-- Message Input -->
            <label class="block">
              <span class="text-gray-700">Your message:</span>
              <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
            </label>
  
            <!-- Submit Button -->
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Send
            </button>
          </form>
        </div>
  </div>


</section>




<section class="bg-gradient-to-b from-yellow-50 to-purple-100 h-auto pb-20">
  <div class='max-w-screen-xl mx-auto'>
    <div class="relative">
      <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
       How it works
      </h2>
    </div>
    <div class='max-w-xl mx-auto lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>
      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Free Consultation
          </span>
        </div>
        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight sm:leading-tighter font-bold'>
          Step 1: Free Orthodontic Consultation
        </h3>
        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
          Your first visit will consist of diagnostic records, an exam, your smile goals, and financing options. Each patient’s orthodontic treatment is different so we leave ample time for questions.

        </p>
        <div class='mt-6'>
        </div>
      </div>
      <div class='relative lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-2 aspect-h-3 rounded-2xl pt-16">
            <img class="object-cover rounded-2xl" loading="lazy" src="../../../../assets/images1/consult_2.webp" alt="Invisalign Consultation">
          </div>
        </div>
      </div>
    </div>
    <div class='max-w-xl mx-auto  mt-10 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
      <div class='flex flex-col justify-center  sm:mt-44 lg:mt-0 lg:col-span-6'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Personalized Treatment
          </span>
        </div>
        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight  sm:leading-tighter font-bold'>
          Step 2: Begin Your Orthodontic Journey
        </h3>
        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
          Following your consultation, we'll begin your treatment with braces or aligners. You will have regular appointments every 6 weeks will ensure your treatment is progressing as planned, with adjustments made as necessary.
      </p>
        <div class='mt-6'>
        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6'>
        <div class='relative '>
          <div class="w-full rounded-3xl px-10 lg:px-0">
            <img class="object-cover rounded-3xl "  loading="lazy" src="../../../../assets/images1/invisalign_1.webp" alt="girl with beautiful smile">
          </div>

        </div>
      </div>

    </div>

    <div class='max-w-xl mx-auto mt-10 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Embrace Your New Smile
          </span>
        </div>
        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight sm:leading-tighter font-bold'>
          Step 3: Smile
        </h3>
        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
          Once you've finished your treatment show off your new smile!
      </p>
        <div class='mt-6'>
        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-3 aspect-h-4 rounded-3xl">
            <img class="object-cover rounded-3xl"  loading="lazy" src="../../../../assets/images1/smile_1.webp" alt="girl with beautiful smile">
          </div>

        </div>
      </div>

    </div>

  </div>

  <div class='mt-20 lg:mt-24 lg:flex flex-col items-center'>
    <p class="text-xl tracking-wider text-purple-900 uppercase">
      Celebrating a Legacy of Smiles - Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
    </p>
    <!-- Star Ratings and Call to Action Section -->
    <!-- Insert additional content or call-to-action here -->
  </div>
</section>




<section class='relative w-full px-4 py-14 bg-purple-100   sm:px-6 lg:px-8'>

  <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Located in Corona
  </h1>
  <div class=' lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
    <iframe class='w-full rounded-3xl'
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.92396507248!2d-117.5556445!3d33.8579374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43116ec56c21e3be!2sKetcherside%20Orthodontics%20Inc.!5e0!3m2!1sen!2sus!4v1664564711858!5m2!1sen!2sus"
      height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</section>
}