import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-implants',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './implants.component.html',
  styleUrl: './implants.component.scss'
})
export class ImplantsComponent {

  title = 'Dental Implants Specialist - Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Trusted Dental Implants Specialist serving Corona, CA. Contact us at (909) 793-1595 or visit us at 720 Magnolia Ave #1, Corona, CA 92879' }
    );
    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@type": "Service",
          "serviceType": "Dental Implants in Corona, CA",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Ketcherside Orthodontics"
          },
          "areaServed": {
            "@type": "City",
            "name": "Corona"
          },
          "category": [
            "Dental Implants",
            "Implant Dentist"
          ],
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Dental Implants",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Single Implant"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Multiple Implants"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "One Arch Implants"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Full Set Implants"
                }
              }
            ]
          },
          "openingHours": [
            "Mo-Fr 9:00-17:00"
          ],
          "telephone": "+19097931595",
          "email": "drgaryk1@gmail.com",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Corona",
            "addressRegion": "CA",
            "postalCode": "92879",
            "streetAddress": "720 Magnolia Ave Suite #1"
          },
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$",
          "image": "https://ketchersideortho.com/assets/images1/logo1.svg"
        }`;

        this._renderer2.appendChild(this._document.body, script);

  }





  isShown: boolean = true ; // hidden by default\
  isShown1: boolean = false ; // hidden by default
  isShown2: boolean = false ; // hidden by default
  isShown3: boolean = false ; // hidden by default


  toggleShow() {
  this.isShown = ! this.isShown;
  this.isShown1 = this.isShown2 = this.isShown3 = false
}
toggleShow1() {
  this.isShown1 = ! this.isShown1;
  this.isShown = this.isShown2 = this.isShown3 = false
}
toggleShow2() {
  this.isShown2 = ! this.isShown2;
  this.isShown1 = this.isShown = this.isShown3 = false
}
toggleShow3() {

  this.isShown3 = ! this.isShown3;
  this.isShown1 = this.isShown2 = this.isShown = false

}

sidebar: boolean = false

sidebarToggle() {
  this.sidebar = !this.sidebar
}

sidebarFalse() {
  setTimeout(()=>{                           // <<<---using ()=> syntax
    this.sidebar = false;
}, 120);
}

navbarContentList:string[] = ['']

}
