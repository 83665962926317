import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';



@Component({
  selector: 'app-extractions',
  standalone: true,
  imports: [],
  templateUrl: './extractions.component.html',
  styleUrl: './extractions.component.scss'
})
export class ExtractionsComponent {



  title = 'Teeth Extractions | Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Corona CA Dentist performs tooth extractions when the tooth is too damaged or decayed. (909) 793-1595.' }
    );
  }
}
