
<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div>
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Brighter Smiles Make Brighter Futures

        </span>
      </div>
      <h1 class='max-w-xl mt-4 text-center text-purple-900 sm:mt-5 lg:text-left h1 lg:max-w-none h1'>
        Welcome to Ketcherside Orthodontics Inc
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
        At Ketcherside Orthodontics Inc, we believe everybody deserves a brighter future and strive to give you and your family a personalized dental and orthodontics experience that will give you the best smile.
      </p>

      <div class='flex flex-col items-center mt-8 overflow-hidden sm:flex-row'>
        <a [routerLink]="['/contact']" id="path to contact page and contact form"  class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
          Contact Form

          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="15" y1="16" x2="19" y2="12" />
            <line x1="15" y1="8" x2="19" y2="12" />
          </svg>

        </a>
        <a href="tel:9097931595" class='mt-6 text-lg font-medium text-black bg-purple-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-white hover:bg-purple-600'>
          Call Now



        </a>


      </div>

      <p class="hidden text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mt-14">
        Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
      </p>
      <div class='flex-col hidden mt-8 overflow-hidden divide-y sm:flex sm:mt-5 sm:flex-row sm:divide-x sm:divide-y-0 lg:hidden xl:flex divide-purple-400/20 flex-start'>

        <div class='flex flex-col items-center pt-5 sm:pt-0 sm:pl-10'>
          <div class='flex justify-center w-full space-x-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
          <p class='mt-2.5 text-md font-bold tracking-wide text-purple-700 uppercase'>
            Google Reviews
          </p>
        </div>
      </div>

    </div>

    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl"  src="../../../../assets/images1/hero-home2.webp" alt="Happy Family">

      </div>
    </div>



  </div>

  <div class='flex flex-col items-center mt-20 lg:mt-24 sm:hidden lg:flex xl:hidden'>

    <p class="text-sm font-semibold tracking-wider text-purple-900 uppercase">
      Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
    </p>
    <div class='flex flex-col mt-8 overflow-hidden divide-y sm:divide-y-0 sm:divide-x sm:flex-row divide-purple-400/20 flex-start'>

      <div class='flex flex-col items-center pt-5 sm:pt-0 sm:pl-10'>
        <div class='flex justify-center w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>
        <p class='mt-2.5 text-xs font-bold tracking-wide text-purple-700 uppercase'>
          Google Reviews
        </p>
      </div>
    </div>
  </div>
</section>
@defer (prefetch on idle) {
<section class='w-full h-40 sm:h-48 xl:h-52 bg-gradient-to-b from-purple-100  to-yellow-50 outline-4 outline-gray-900'>

</section>



<section loading="lazy" class='px-4 pb-16 overflow-hidden bg-yellow-50 sm:pb-24 sm:px-6 lg:px-8 outline-1'>

  <div class='max-w-screen-xl mx-auto'>

    <div class="relative">

      <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
        Providing the best Orthodontics & Dental care for you and your family
      </h2>
      <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800">
        When you visit Ketcherside Orthodontics Inc, your smile is our top priority. Our entire team is dedicated to providing you with the personalized, gentle care that you deserve.      </p>

      <div class="max-w-3xl mx-auto mt-12">
        <ul class="flex flex-wrap items-center justify-center -mx-3 -my-2 text-lg text-purple-800">
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Personalized Treatment</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Flexible Financing</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Clear Aligners</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Invisalign</span>
          </li>

          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Braces</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>Hygenist</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>All Dental Procedures</span>
          </li>
          <li class="flex items-center mx-3 my-2">
            <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span>25+ Years Experience</span>
          </li>
        </ul>
      </div>

    </div>

    <div class='max-w-xl mx-auto mt-16 lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>

      <div class='relative lg:col-span-6'>
        <div class='relative sm:pl-36 lg:pl-20 xl:pl-32'>
          <div class="aspect-w-3 aspect-h-4 rounded-2xl">
            <img class="object-cover rounded-2xl" loading="lazy" src="../../../../assets/images1/ortho_3.webp" alt="Girl with braces">
          </div>
          <div class="absolute bottom-0 left-0 hidden sm:block sm:translate-y-1/3 sm:w-72 sm:h-72 lg:w-64 lg:h-64 xl:w-72 xl:h-72 2xl:w-80 2xl:h-80 rounded-3xl">
            <img class="object-cover w-full h-full rounded-3xl" loading="lazy" src="../../../../assets/images1/ortho_2.webp" alt="girl with orthodontics">
          </div>
        </div>
      </div>

      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6'>
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Straight Teeth Start Here!
          </span>
        </div>
        <h3 class='mt-4 text-purple-900 sm:mt-5 h3'>
         Orthodontics
        </h3>
        <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
          With the use of braces, clear braces or aligners, our orthodontists offer the most efficient teeth straightening solutions in the Corona area for fixing each unique orthodontic issue. We appreciate your confidence in us and will do our best to meet or exceed your expectations.        </p>
        <div class='mt-6'>
          <a [routerLink]="['/orthodontics']" id="path to orthodontic page" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
            Orthodontic Services

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>

          </a>
        </div>
      </div>

    </div>

    <div class='max-w-xl mx-auto mt-16 sm:mt-44 lg:mt-56 xl:mt-60 2xl:mt-64 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>

      <div class='relative lg:order-2 lg:col-span-6'>
        <div class='relative sm:pr-36 lg:pr-20 xl:pr-32'>
          <div class="aspect-w-3 aspect-h-4 rounded-3xl">
            <img class="object-cover rounded-3xl"  loading="lazy" src="../../../../assets/images1/dental_1.webp" alt="girl with beautiful smile">
          </div>
          <div class="absolute top-0 right-0 hidden sm:block sm:-translate-y-1/3 sm:w-72 sm:h-72 lg:w-64 lg:h-64 xl:w-72 xl:h-72 2xl:w-80 2xl:h-80 rounded-3xl">
            <img class="object-cover w-full h-full rounded-3xl" loading="lazy" src="../../../../assets/images1/dental_2.webp" alt="girl flossing">
          </div>
        </div>
      </div>

      <div class='flex flex-col justify-center mt-16 sm:mt-20 lg:mt-0 lg:order-1 lg:col-span-6'>
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Your Dental Health Begins Here
          </span>
        </div>
        <h3 class='mt-4 text-purple-900 sm:mt-5 h3'>
          Dentist
        </h3>
        <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
          Your oral health is important to your smile. Keeping your teeth free of decay and preventing any potential problems is essential to maintaining the best oral health. We’ll be happy to help you develop great hygiene practices, and discuss lifestyle changes which can affect your teeth and gums. Prevention is the best way to be proactive about the health of your mouth, including your gums. Early intervention can eliminate larger complications that require more advanced, and often more costly treatments. Our common dental procedures are the dental services many of our patients find effective, and are the most popular treatments we perform.         </p>

        <div class='mt-6'>
          <a [routerLink]="['/dental']" id="path to dental page" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
            Dental Services

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>

          </a>
        </div>

      </div>

    </div>
  </div>

</section>


<section loading="lazy" class='relative w-full px-4 py-16 sm:py-24 sm:px-6 xl:px-8'>

  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
    <div class='grid gap-16 lg:grid-cols-2 xl:grid-cols-11 lg:gap-12 xl:gap-24'>

      <div class='flex flex-col justify-center lg:col-span-1 xl:col-span-6 lg:order-2'>
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Your smile is in good hands
          </span>
        </div>
        <h2 class='mt-4 text-purple-900 sm:mt-5 h2'>
          Meet the team behind the smiles
        </h2>
        <p class="max-w-xl mt-4 text-xl leading-relaxed text-purple-800 md:mt-5">
Our staff is uniquely trained and highly skilled, and we strive to provide energetic and fun-loving service to each patient. Our staff consists of team members with specialized training and multiple certifications, and each one has years of experience in treating patients with the highest level of care.        </p>

        <div class='relative max-w-4xl mt-16 bg-yellow-100 rounded-xl sm:mt-14'>
          <span class="absolute flex items-center justify-center shadow-md left-6 sm:left-10 -top-7 rounded-2xl w-14 h-14 bg-gradient-to-br from-purple-600 to-purple-600">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="15" cy="15" r="3" />
              <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
              <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
              <line x1="6" y1="9" x2="18" y2="9" />
              <line x1="6" y1="12" x2="9" y2="12" />
              <line x1="6" y1="15" x2="8" y2="15" />
            </svg>
          </span>
          <div class='px-4 py-10 mt-2 sm:px-10 sm:py-12'>
            <p class='text-lg font-semibold text-purple-900 sm:text-xl'>At Ketcherside Orthodontics, we pride ourselves on providing the highest quality dental and orthodontic procedures at the most affordable prices. You can expect the following from us:</p>

            <ul class="mt-5 space-y-5 text-lg text-purple-800">

              <li class="flex items-center">
                <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
                <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                  <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                </svg>
                <span>25+ years of experience
                </span>
              </li>


              <li class="flex items-center">
                <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
                <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                  <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                </svg>
                <span>Amazing recommendations and positive reviews</span>
              </li>

              <li class="flex items-center">
                <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
                <svg loading="lazy" class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                  <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                </svg>
                <span>A love and passion for improving your smile</span>
              </li>

              <li class="flex items-center">
                <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
                <svg loading="lazy" class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                  <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                </svg>
                <span>A commitment to creating a positive experience</span>
              </li>

            </ul>

            <button class="mt-10 font-medium text-white bg-purple-600 btn-sm hover:bg-purple-500 group" id="button to about page">
              <a [routerLink]="['/about']">
              Meet the rest of the team
            </a>
            </button>

          </div>
        </div>

      </div>

      <div class='grid w-full gap-10 mx-auto sm:gap-8 lg:col-span-1 xl:col-span-5 lg:mt-20 sm:grid-cols-2 lg:gap-4 xl:gap-8 lg:order-1 sm:max-w-none sm:mx-0'>

        <div class='lg:-translate-y-20'>
          <div class='aspect-w-2 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' loading="lazy" src='../../../../assets/images1/brandie.webp' alt='Brandie team member'>
          </div>
          <div class='pl-2 sm:pl-0 mt-3.5 text-lg sm:text-center'>
            <p class='font-semibold tracking-wide text-purple-800'>
              Brandie
            </p>
            <p class='text-base font-medium text-purple-600'>
              Office Manager
            </p>
          </div>
        </div>

        <div>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' loading="lazy" src='../../../../assets/images1/drwong.webp' alt='Dr Wong Oral Surgeon'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-purple-800'>
              Dr Wong
            </p>
            <p class='text-base font-medium text-purple-600'>
              Oral Surgeon
            </p>
          </div>
        </div>

        <div class='lg:-translate-y-20'>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' loading="lazy" src='../../../../assets/images1/drgary.webp' alt='Dr Ketcherside orthodontist'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-purple-800'>
              Dr Ketcherside
            </p>
            <p class='text-base font-medium text-purple-600'>
              Orthodontist
            </p>
          </div>
        </div>

        <div>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' loading="lazy" src='../../../../assets/images1/ryan1.webp' alt='Dr Ryan General Dentist'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-purple-800'>
              Dr Ryan
            </p>
            <p class='text-base font-medium text-purple-600'>
              Dentist
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section loading="lazy" class='px-4 pt-16 pb-24 overflow-hidden sm:pb-28 md:pb-0 sm:pt-24 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto md:max-w-screen-xl'>

    <div class='md:grid md:grid-cols-2 md:gap-12 lg:gap-16'>
      <div>
        <h2 class='max-w-4xl text-purple-900 h2'>
          Speciality Services
        </h2>
        <p class='max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-purple-800 sm:mt-5 lg:text-left'>
          You and your family can take advantage of a full range of dental and orthodontic services here at Ketcherside Orthodontics Inc. During each visit, you will be treated by trained professionals who will provide top quality care to prevent dental problems and treat current conditions.        </p>
      </div>
    </div>

    <div class='mt-16 md:mt-72 md:grid md:grid-cols-2 md:gap-8 lg:gap-16'>

      <div class='relative'>
        <img src='../../../../assets/images1/curved-dotted-line.svg' loading="lazy" alt="dotted line" class='absolute top-0 hidden -translate-y-1/2 md:block left-1/2' style="width: calc(50% + 4rem);">

        <div class='relative z-10 w-full px-8 py-10 sm:p-12 md:px-8 md:py-10 lg:p-12 bg-purple-25 rounded-3xl bg-purple-100'>
          <div class='flex flex-col justify-between'>
            <div class='flex-1'>
              <h3 class='text-purple-900 h3'>
                Teeth Whitening
              </h3>
              <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
                Zoom Teeth Whitening System uses proprietary technology to whiten your teeth up to eight shades in a little over an hour while you relax
                                     </p>
              <div class='relative mt-8 aspect-w-3 aspect-h-2'>
                <img loading="lazy"
                  class="object-cover rounded-2xl"
                  src="../../../../assets/images1/zoom1.webp"
                  alt="Zoom Teeth Whitening"
                  title="Teeth Whitening in Corona"
                />
              </div>
            </div>
            <div class='mt-6'>
              <a [routerLink]="['/teeth-whitening']"  class="text-lg font-semibold text-white bg-blue-800  hover:bg-blue-700 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none " id="Path to Teeth Whitening">
                About Teeth Whitening

                <!-- Tablericon name: arrow-narrow-right -->
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="15" y1="16" x2="19" y2="12" />
                  <line x1="15" y1="8" x2="19" y2="12" />
                </svg>

              </a>
            </div>
          </div>
        </div>

        <img src='../../../../assets/images1/looped-dotted-line.svg'  loading="lazy" alt="dotted line" class='hidden md:block absolute bottom-0 left-1/2 translate-y-[90%]' style="width: calc(50% + 4rem);">
      </div>

      <div class='relative w-full px-8 py-10 mt-12 bg-yellow-200 sm:p-12 md:mt-0 md:px-8 md:py-10 lg:p-12 md:-translate-y-80 rounded-3xl'>
        <div class='flex flex-col justify-between'>
          <div class='flex-1'>

            <h3 class='text-purple-900 h3'>
              Invisalign
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
              If you’ve ever wanted to straighten your smile without the hassle of obvious metal braces, Invisalign clear aligners from Ketcherside Orthodontics could be just what you’re looking for.

            </p>
            <div class='relative mt-8 aspect-w-3 aspect-h-2'>
              <img loading="lazy"
                class="object-cover rounded-2xl"
                src="../../../../assets/images1/invisalignadult.webp"
                alt="Girl holding invisalign trays"
              />
            </div>
          </div>
          <div class='mt-6'>
            <a [routerLink]="['/invisalign']"  class="text-lg font-semibold text-white bg-blue-800  hover:bg-blue-700 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none " id="button to invisalign page">
              About Invisalign

              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="15" y1="16" x2="19" y2="12" />
                <line x1="15" y1="8" x2="19" y2="12" />
              </svg>

            </a>
          </div>
        </div>
      </div>

      <div class='relative w-full px-8 py-10 mt-12 sm:p-12 md:mt-0 md:col-start-2 md:px-8 md:py-10 lg:p-12 md:-translate-y-80 bg-blue-200 rounded-3xl'>
        <div class='flex flex-col justify-between'>
          <div class='flex-1'>
            <h3 class='text-purple-900 h3'>
              Teeth Cleaning
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
              Brushing and flossing at home are going to be lifelong crucial parts of keeping your oral health at its best. But even if you have excellent at-home routines, you will need a teeth cleaning in Corona from time to time            </p>
            <div class='relative mt-8 aspect-w-3 aspect-h-2'>
              <img loading="lazy"
                class="object-cover rounded-2xl"
                src="../../../../assets/images1/cleaning.webp"
                alt="Girl getting teeth cleaned"
              />
            </div>
          </div>
          <div class='mt-6'>
            <a [routerLink]="['/teeth-cleaning']" id="path to teeth cleaning page" class="text-lg font-semibold text-white bg-blue-800  hover:bg-blue-700 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
              About Teeth Cleaning

              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="15" y1="16" x2="19" y2="12" />
                <line x1="15" y1="8" x2="19" y2="12" />
              </svg>

            </a>
          </div>
        </div>
      </div>

    </div>

  </div>

</section>

<section loading="lazy" class='py-20 bg-[#4a499c] sm:py-28 lg:py-32 md:-mt-48'>

  <div class='px-4 mx-auto lg:max-w-screen-2xl sm:px-6'>

    <div class='flex flex-col items-center justify-center'>
      <h2 class='max-w-2xl text-center text-white h2'>
        See what our patients are saying about us
      </h2>

    </div>

    <div class='grid gap-8 mt-12 md:gap-8 sm:gap-6 md:mt-14 lg:mt-16 xl:mt-20 2xl:mt-24 xl:grid-cols-4 sm:grid-cols-2 2xl:gap-12 lg:gap-6'>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-yellow-200 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img loading="lazy" src='../../../../assets/images1/d.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full filter drop-shadow-2xl' alt='Testimonial 01'>
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Devyn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “I had my braces done by Dr. Ketcherside and he did an amazing job. Him and his staff are always so welcoming, kind and very helpful. I highly recommend going to Dr. Ketcherside. You will not be disappointed."        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-purple-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img loading="lazy" src='../../../../assets/images1/a.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full filter drop-shadow-2xl' alt="Testimonial 02">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Autumn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "Friendly staff, the office is really nice and clean. This was my first visit and they explained all the procedures clearly and made me feel comfortable."        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-rose-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img src='../../../../assets/images1/g.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full filter drop-shadow-2xl' alt="Testimonial 03">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          G J
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “This office is the best! The front desk staff are amazing and friendly!  Dr Ketcherside is very kind and professional. I highly recommend this office to anyone looking for dental care and to be treated like royalty while you are there.”        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-blue-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img  loading="lazy" src='../../../../assets/images1/k.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full filter drop-shadow-2xl' alt="Testimonial 04">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Katie
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "We have been coming to Dr. Ketcherside in Corona for over 7yrs. Myself and my 3 children has had amazing work done on our teeth by the Dr. The Dr & Staff are amazing!!!  Super welcoming and friendly. Clean office and hands down give out the best cookies."        </p>
      </blockquote>

    </div>

  </div>

</section>







<section loading="lazy" class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' loading="lazy" src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="underline">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" alt="image of phone">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>
}