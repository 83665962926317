import { Component } from '@angular/core';

@Component({
  selector: 'app-aligners',
  standalone: true,
  imports: [],
  templateUrl: './aligners.component.html',
  styleUrl: './aligners.component.scss'
})
export class AlignersComponent {

}
