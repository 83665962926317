<section loading="lazy" class='px-4 pb-12 overflow-hidden bg-purple-100 lg:pt-24 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-32 lg:max-w-screen-xl '>

    <div class='py-16 lg:py-32'>
      <div>
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Contact us today
        </span>
      </div>
      <h1 class='max-w-md mt-4 text-purple-900 h1'>
        We'd love to hear from you
      </h1>
      <p class='max-w-lg mt-3 text-xl leading-relaxed text-purple-800'>
        Fill out our form and we'll contact you regarding any questions you might have.
      </p>
    </div>

    <div class='relative'>

      <img src='../../../../assets/images1/dots-large-grid.svg'
        class='absolute lg:hidden -right-16 -top-12 sm:-top-16 w-80 lg:left-14 lg:-top-16 lg:w-36 opacity-60' alt="dot decoration">
      <img src='../../../../assets/images1/dots-grid.svg'
        class='absolute hidden w-40 opacity-75 lg:block -right-16 -top-16 lg:left-14 lg:-top-16 lg:w-36' alt="dot decoration">
      <img src='../../../../assets/images1/dots-strip.svg'
        class='absolute hidden w-20 rotate-90 opacity-75 lg:block -right-16 top-1/2' alt="dot decoration">


      <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-2xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Send us a message
          </h3>
          <p class='text-purple-800 mt-0.5 text-opacity-90'>
            We'll get back to you within 24 hours.
          </p>
        </div>

        <div class="pt-7"></div>
        <form action="https://formspree.io/f/xoqolkjo" method="POST" class="flex flex-col space-y-4 bg-purple-50 p-4 shadow-2xl rounded-lg">

          

          <label class="block">
            <span class="text-gray-700">Your name:</span>
            <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
          </label>

          <!-- Email Input -->
         

          <!-- Phone Number Input -->
          <label class="block">
            <span class="text-gray-700">Phone number:</span>
            <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="123-456-7890">
          </label>

          <!-- Best Time to Contact Input -->
       

          <!-- Preference for Text or Call -->
          <label class="block">
            <span class="text-gray-700">Prefer Contact Method:</span>
            <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option value="text">Text</option>
              <option value="call">Call</option>
            </select>
          </label>

          <!-- Message Input -->
          <label class="block">
            <span class="text-gray-700">Your message:</span>
            <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
          </label>

          <!-- Submit Button -->
          <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Send
          </button>
        </form>

      </div>

    </div>

  </div>

</section>

<section class='relative w-full px-4 pt-56 bg-white -mb-52 -translate-y-52 sm:pt-64 lg:pt-72 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>

    <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
      <div class='flex items-center'>
        <h2 class='max-w-4xl text-purple-900 h2'>
          Contact information
        </h2>
      </div>

    </div>

    <div class='grid grid-cols-1 gap-4 mt-12 sm:mt-14 lg:mt-20 sm:grid-cols-4 lg:grid-cols-3 sm:gap-6 xl:gap-12'>

      <div class='px-4 py-8 bg-yellow-200 sm:col-span-2 lg:col-span-1 sm:p-8 rounded-3xl'>
        <div class="flex sm:flex-col lg:flex-row">
          <div>
            <span class="flex items-center justify-center bg-yellow-400 rounded-2xl w-14 h-14">

              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="11" r="3" />
                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
              </svg>

            </span>
          </div>
          <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
            <h5 class="flex items-center text-xl font-semibold text-purple-900">
              Address
            </h5>
            <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              720 Magnolia Ave Suite #1, Corona, CA 92879
            </p>
          </div>
        </div>
      </div>

      <div class='px-4 py-8 sm:col-span-2 lg:col-span-1 sm:p-8 sm:py-10 rounded-3xl bg-purple-50'>
        <div class="flex sm:flex-col lg:flex-row">
          <div>
            <span class="flex items-center justify-center bg-purple-200 rounded-2xl w-14 h-14">

              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>

            </span>
          </div>
          <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
            <h5 class="flex items-center text-xl font-semibold text-purple-900">
              Email us
            </h5>
            <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              drgaryk1&#64;gmail.com
            </p>
          </div>
        </div>
      </div>

      <div
        class='px-4 py-8 sm:col-start-2 sm:col-span-2 lg:col-start-3 lg:col-span-1 sm:p-8 sm:py-10 rounded-3xl bg-rose-50'>
        <div class="flex sm:flex-col lg:flex-row">
          <div>
            <span class="flex items-center justify-center rounded-2xl w-14 h-14 bg-rose-200">

              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>

            </span>
          </div>
          <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
            <h5 class="flex items-center text-xl font-semibold text-purple-900">
              Call us
            </h5>
            <a href="tel:9097931595" class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              (909) 793-1595
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class='mt-16 lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
    <iframe class='w-full rounded-3xl'
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.92396507248!2d-117.5556445!3d33.8579374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43116ec56c21e3be!2sKetcherside%20Orthodontics%20Inc.!5e0!3m2!1sen!2sus!4v1664564711858!5m2!1sen!2sus"
      height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>

</section>

<section class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14'
            src='../../../../assets/images1/underline-simple-light-purple.svg'>
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595"
        class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44"
          height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>
