<div class='hidden px-4 lg:block sm:px-6 bg-purple-50'>

  <!-- Container -->
  <div class='relative max-w-screen-xl py-5 mx-auto border-b border-purple-200/30'>
    <div class='flex items-center justify-between'>

      <!-- Site branding -->
      <div class="flex-grow-0 flex-shrink-0 w-32">
        <a [routerLink]="['/']">
          <img src="../../../../assets/images1/logo1.svg" alt="Bright" class="h-auto" />
        </a>
      </div>

      <!-- Contact information -->
      <ul class='flex ml-8 lg:space-x-6 xl:space-x-16'>

        <!-- Address -->
        <li class="flex flex-shrink max-w-xs">
          <div>
            <span class="flex items-center justify-center bg-yellow-400 rounded-2xl w-11 h-11">

              <!-- TablerIcon name: map-pin -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="11" r="3" />
                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
              </svg>

            </span>
          </div>
          <div class="flex-1 mt-0 ml-3 xl:ml-4">
            <h5 class="flex items-center text-base font-semibold text-purple-900">
              Address
            </h5>
            <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              720 Magnolia Ave Suite #1, Corona, CA 92879
            </p>
          </div>
        </li>

        <!-- Email -->
        <li class="flex flex-shrink-0">
          <div>
            <span class="flex items-center justify-center bg-purple-200 rounded-2xl w-11 h-11">

              <!-- TablerIcon name: mail -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>

            </span>
          </div>
          <div class="flex-1 ml-3 xl:ml-4">
            <h5 class="flex items-center text-base font-semibold text-purple-900">
              Email
            </h5>
            <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              drgaryk1&#64;gmail.com
            </p>
          </div>
        </li>

        <!-- Phone number -->
        <li class="flex flex-shrink-0">
          <div>
            <span class="flex items-center justify-center rounded-2xl w-11 h-11 bg-rose-200">

              <!-- TablerIcon name: phone -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-700" width="44" height="44"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>

            </span>
          </div>
          <div class="flex-1 ml-3 xl:ml-4">
            <h5 class="flex items-center text-base font-semibold text-purple-900">
              Phone
            </h5>
            <a href="tel:9095315179" class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
              (909) 531-5179
            </a>
          </div>
        </li>

      </ul>

    </div>
  </div>
</div>

<div class='px-4 pb-2 sm:px-6 bg-purple-50'>
  <nav class='flex items-center max-w-screen-xl pt-5 mx-auto'>
    <div class='flex items-center justify-between w-full'>

      <div class='items-center justify-between hidden lg:flex md:space-x-6 lg:space-x-10'>
        <a [routerLink]="['/']">
          <div class="relative p-0.5 group">
            <span
              class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Home</span>
            <span
              class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
          </div>
        </a>
        <a routerLink="/about">
          <div class="relative p-0.5 group">
            <span
              class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">About</span>
            <span
              class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
          </div>
        </a>
        <div class="dropdown dropdown-hover">
          <label tabindex="0"
            class="relative z-10 flex items-center text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600 cursor-pointer">
            Dentistry Services &#9660;
          </label>
          <ul tabindex="0" class="dropdown-content menu text-xl p-4 shadow grid grid-cols-2 bg-white text-black rounded-2xl w-99">
            <div class="col-start-1">
              <!-- General Dentistry -->
              <li class="font-semibold py-2 "><a [routerLink]="['/dental']">General Dentistry</a></li>
              <li><a [routerLink]="['/exams']"> Exams</a></li>
              <li><a [routerLink]="['/fillings']">Fillings</a></li>
              <li><a [routerLink]="['/crowns']">Crowns</a></li>
              <li><a [routerLink]="['/rootcanals']">Root Canals</a></li>
              <li><a [routerLink]="['/teeth-cleaning']">Teeth Cleaning</a></li>
              <li><a [routerLink]="['/wisdomteeth']">Wisdom Teeth Extractions</a></li>
            </div>
            <div class="col-start-2">
              <!-- Children's Dentistry -->
              <!-- <li class="font-bold py-2">Children's Dentistry</li>
              <li><a [routerLink]="['/childrens-dentist']">Pediatric Checkups</a></li>
              <li><a [routerLink]="['/childrens-fluoride-treatment']">Fluoride Treatments</a></li> -->

              <!-- Surgical Dentistry -->
              <li class="font-bold py-2">Surgical Dentistry</li>
              <li><a [routerLink]="['/implants']">Dental Implants</a></li>

              <!-- Cosmetic Dentistry -->
              <li class="font-bold py-2">Cosmetic Dentistry</li>
              <li><a [routerLink]="['/bonding']">Bonding</a></li>
              <li><a [routerLink]="['/veneers']">Veneers</a></li>
              <li><a [routerLink]="['/teeth-whitening']">Teeth Whitening</a></li>
            </div>
          </ul>
        </div>
        <div class="dropdown dropdown-hover">
          <div class="flex">
            <label [routerLink]="['/orthodontics']" tabindex="0"
              class="relative z-10 flex items-center text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600 cursor-pointer">Orthodontics
              &#9660;</label>
          </div>
          <ul tabindex="0" class="dropdown-content menu text-xl p-4 shadow bg-white text-black rounded-2xl w-52">
            <li><a [routerLink]="['/orthodontics']">Orthodontics</a></li>
            <li><a [routerLink]="['/braces']">Braces</a></li>
            <li><a [routerLink]="['/invisalign']">Invisalign</a></li>
            <li><a [routerLink]="['/teeninvisalign']">Teen Invisalign</a></li>
            <li><a [routerLink]="['/clearbraces']">Clear Braces</a></li>
            <li><a [routerLink]="['/aligners']">Clear Aligners</a></li>
            <li><a [routerLink]="['/orthodonticprice']">Braces Pricing</a></li>
          </ul>
        </div>
        <a [routerLink]="['/insurance']">
          <div class="relative p-0.5 group">
            <span
              class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Financing
              & Insurance</span>
            <span
              class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
          </div>
        </a>
        <a [routerLink]="['/contact']">
          <div class="relative p-0.5 group">
            <span
              class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Contact</span>
            <span
              class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
          </div>
        </a>
      </div>

      <!-- Call to action button -->
      <div class='hidden lg:block'>
        <a href="tel:9095315179"
          class=" text-lg font-medium text-purple-900 bg-yellow-300 hover:bg-yellow-400 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none">
          Call Today
        </a>
      </div>
    </div>
  </nav>
</div>


<!-- Logo on smaller screens: < lg -->
<div class="flex justify-between px-4 lg:hidden bg-purple-50">
  <div class="flex">
    <a [routerLink]="['/']">
      <div class="block w-48">
        <img src="../../../assets/images1/logo1.svg" alt="Ketcherside Orthodontics Logo" class="h-auto" />
      </div>
    </a>
  </div>

  <div class="flex justify-end z-50">
    <button class="btn btn-link btn-circle" (click)="toggleSidebar()">
      <!-- Toggle between hamburger and close icon based on sidebar state -->
      <svg *ngIf="!sidebar" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
        <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
      </svg>
      <svg *ngIf="sidebar" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
        <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
      </svg>
    </button>
  </div>
</div>

<div *ngIf="sidebar" class="fixed z-50 w-full px-4">
  <button (click)="toggleSidebar()" class="absolute top-0 right-0 m-4">Close</button>
  <ul class="bg-gradient-to-bl from-purple-50 to-violet-200 text-center text-lg rounded-xl divide-y divide-gray-300 border-gray-300 border overflow-y-auto max-h-screen">
    <!-- Sidebar content here -->
  </ul>
</div>
<div *ngIf="sidebar" class="fixed z-50 w-full px-4">
  <button (click)="toggleSidebar()" class="absolute top-0 right-0 m-4">Close</button>

  <ul
    class="bg-gradient-to-bl from-purple-50 to-violet-200 text-center text-xl rounded-xl divide-y divide-gray-300 border-gray-300 border"
    >
    <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/about']" href="/about" routerLinkActive="text-pink-600 font-bold">About </a></li>
    <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/contact']" href="/contact" routerLinkActive="text-pink-600 font-bold">Contact </a></li>

    <li class="py-4" (click)="toggleAccordion('dental')">Dental Services</li>
    <ul *ngIf="accordions['dental']" class="pl-4">
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/dental']" href="/dental" routerLinkActive="text-pink-600 font-bold">Dentist </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/crowns']" href="/crowns" routerLinkActive="text-pink-600 font-bold">Crowns </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/fillings']" href="/fillings" routerLinkActive="text-pink-600 font-bold">Fillings </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/bonding']" href="/bonding" routerLinkActive="text-pink-600 font-bold">Bonding </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/exams']" href="/exams" routerLinkActive="text-pink-600 font-bold">Dental Exam </a></li>

      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/implants']" href="/implants" routerLinkActive="text-pink-600 font-bold">Dental Implants </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/veneers']" href="/veneers" routerLinkActive="text-pink-600 font-bold">Dental Veneers </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/teeth-cleaning']" href="/teeth-cleaning" routerLinkActive="text-pink-600 font-bold">Teeth Cleaning </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/teeth-whitening']" href="/teeth-whitening" routerLinkActive="text-pink-600 font-bold">Teeth Whitening </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/wisdomteeth']" href="/teeth-whitening" routerLinkActive="text-pink-600 font-bold">Wisdom Teeth Extractions </a></li>

      <!-- Add more dental services here -->
    </ul>

    <li class="py-4" (click)="toggleAccordion('orthodontics')">Orthodontic Services</li>
    <ul *ngIf="accordions['orthodontics']" class="pl-4">
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/orthodontics']" href="/orthodontics" routerLinkActive="text-pink-600 font-bold">Orthodontics </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/braces']" href="/braces" routerLinkActive="text-pink-600 font-bold">Braces </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/invisalign']" href="/invisalign" routerLinkActive="text-pink-600 font-bold">Invisalign </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/teeninvisalign']" href="/teeninvisalign" routerLinkActive="text-pink-600 font-bold">Teen Invisalign </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/clearbraces']" href="/clearbraces" routerLinkActive="text-pink-600 font-bold">Clear Braces </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/aligners']" href="/aligners" routerLinkActive="text-pink-600 font-bold">Clear Aligners </a></li>
      <li class="py-2"> <a (click)="toggleSidebar()" [routerLink]="['/orthodonticprice']" href="/orthodonticprice" routerLinkActive="text-pink-600 font-bold">Orthodontic Pricing </a></li>

      <!-- Add more orthodontic services here -->
    </ul>

    <!-- Other menu items -->
  </ul>
</div>
