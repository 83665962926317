import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-bonding',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bonding.component.html',
  styleUrl: './bonding.component.scss'
})
export class BondingComponent {

  title = 'Dental Bonding in Corona';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Corona CA Dentist offers dental bonding as a solution for chipped or discolored teeth. Call (909) 793-1595' }
    );
  }
  accordionItems = [
    {
      question: 'What is dental bonding?',
      answer: 'Dental bonding is a procedure where a tooth-colored resin material is applied to a tooth and then hardened with a special light. This process bonds the material to the tooth to restore or improve a person\'s smile. It\'s used for cosmetic purposes as well as to repair decayed, chipped, fractured, or discolored teeth.',
      open: false,
    },
    {
      question: 'Is dental bonding a painful process?',
      answer: 'Dental bonding is generally not painful and is a minimally invasive procedure. Most patients do not require anesthesia unless the bonding is being used to fill a decayed tooth. The process involves little to no discomfort.',
      open: false,
    },
    {
      question: 'How long does dental bonding last?',
      answer: 'Dental bonding typically lasts several years, but this can vary based on the extent of bonding, the location of the bonded tooth, and your oral habits. To increase the longevity of dental bonding, it\'s important to maintain good oral hygiene, avoid biting on hard objects, and visit your dentist regularly.',
      open: false,
    },
    {
      question: 'Can dental bonding be done in one visit?',
      answer: 'Yes, in most cases, dental bonding can be completed in one office visit. The procedure is simple and doesn\'t require custom-fabricated materials, making it a quick and convenient option for improving the aesthetics of your smile.',
      open: false,
    },
    {
      question: 'How should I care for my bonded teeth?',
      answer: 'Caring for bonded teeth involves the same routine as your natural teeth. Regular brushing and flossing, along with routine dental check-ups, are important. It\'s also advisable to avoid habits like nail biting, chewing on ice, or using your teeth to open packaging to maintain the integrity of the bonding material.',
      open: false,
    },
    {
      question: 'Is dental bonding covered by insurance?',
      answer: 'Dental bonding for cosmetic purposes is generally not covered by insurance. However, if the bonding is used for structural reasons, such as filling a cavity, it may be partially or fully covered. It\'s best to consult with your insurance provider for details about your specific coverage.',
      open: false,
    },
    {
      question: 'Can the bonding material be matched to the color of my teeth?',
      answer: 'Yes, the resin used in dental bonding can be closely matched to the color of your natural teeth. Your dentist will select a composite resin shade that blends in seamlessly with your existing teeth, ensuring that the bonding enhances your smile naturally.',
      open: false,
    },
  ];



  toggleItem(item: any): void {
    item.open = !item.open;
  }
}
