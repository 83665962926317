import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-invisalignlp',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './invisalignlp.component.html',
  styleUrl: './invisalignlp.component.scss'
})
export class InvisalignlpComponent {

}
