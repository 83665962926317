<section class='bg-gradient-to-b from-purple-50 to-yellow-50 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          A Healthy Smile Starts Here
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Teeth Cleanings in Corona, CA
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Maintains oral health and prevents gum diseases</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Brightens your smile by removing stains and plaque</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Enhances overall health by reducing risks of certain diseases</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Early detection of potential dental issues</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Refreshes your breath and keeps it odor-free</span>
        </li>
      </p>
    </div>

    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" height="396" width="600" src="../../../../assets/images1/cleaning.webp" alt="Professional Teeth Cleaning">
      </div>
    </div>
  </div>


</section>





<section class='px-4 overflow-hidden bg-yellow-50 pb-28 sm:pb-36 sm:px-6 lg:px-8'>

  <div class='max-w-screen-xl mx-auto'>

    <div class="px-4 pt-32 text-center sm:px-6 lg:px-8">
			<h3 class="text-purple-900 h1">
        <span class="block"> </span>

        <span class="relative block">
          <span class='relative'>
            <img class='absolute inset-0 transform translate-y-9 sm:translate-y-10 xl:translate-y-12' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline">
            <span class='relative'>Teeth Cleaning process</span>
          </span>
        </span>

        <p class="max-w-2xl mx-auto pt-12 font-normal text-xl leading-relaxed text-center text-blue-800 sm:mt-5">

          Both routine dental cleanings and deep cleanings play important roles in preventing infection and tooth loss caused by gum disease. If it’s been over 6 months since your last cleaning its time to reschedule your teeth cleaning in Corona!


        </p>

      </h3>
		</div>

    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Gentle removal of debris
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Plaque and tartar removal
          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Brushing and flossing stops plaque from building up and hardening into tartar. Once you have tartar, you can only have it removed at your dentist’s office.         </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-yellow.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='yellow blob decoration'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt='dot decoration'>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">
          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../../assets/images1/dots.svg' alt='dot decoration'>

            <figure class='md:order-1'>
              <img src='../../../../assets/images1/teethcleaning_step1.webp' class='object-cover object-center shadow-xl rounded-3xl scale-125' alt='Teeth Scaling'>
            </figure>

          </div>
        </div>
      </div>

    </div>

    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Polishing
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Gritty toothpaste cleaning
          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Professional cleanings use toothpaste that smells and tastes like regular toothpaste, though you can often choose between flavors. However, it has a gritty consistency that gently scrubs your teeth
          </p>
          </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-purple.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='purple blob decoration'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt='dot decoration'>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mx-0">
          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -right-20 xl:w-48 xl:-top-20 xl:-right-20' src='../../../../assets/images1/dots.svg' alt='dot decoration'>

            <figure class='aspect-w-12 aspect-h-10 md:order-1'>
              <img src='../../../../assets/images1/teethcleaning_step2.webp' class='object-cover object-center w-full h-full shadow-xl rounded-3xl scale-125' alt='Teeth Polishing'>
            </figure>

          </div>
        </div>
      </div>

    </div>

    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Wash out
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Floss & Rinse          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Our hygienist will professionally floss your teeth and finish your teeth cleaning by rinsing out any leftover plaque or toothpaste. Enjoy your new minty-fresh smile!
           </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-rose.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='rose blob decoration'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt='dot decoration'>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">

          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../../assets/images1/dots.svg' alt='dot decoration'>

            <figure class='aspect-w-12 aspect-h-10 md:order-1'>
              <img src='../../../../assets/images1/teethcleaning_step3.webp' class='object-cover object-center w-full h-full shadow-xl rounded-3xl scale-125' alt='Teeth cleaning rinse'>
            </figure>
          </div>
        </div>

      </div>
    </div>

  </div>

</section>







<section class='px-4 -mt-8  bg-white sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      All About Teeth Cleanings
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          How Often do I Need Teeth Cleaning?
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          We tend to recommend that patients get a regular cleaning twice a year, typically at the same time that they come in for their routine exam and checkup. By combining these two processes, you can get the care and treatment you need all at once. Receiving professional cleaning every six months allows us to keep your teeth at their best while also catching any trouble signs before they become bigger issues.            </p>


            <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
              Comfortable and easy


            </h2>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Modern teeth cleaning is quick, comfortable, and effective. Your teeth cleaning will typically consist of a few parts: removing buildup, polishing, and then a fluoride treatment. Removing buildup is perhaps the most important, as this plaque and tartar can lead to tooth decay down the road. And left untreated, tooth decay can cause cavities, infections, or even tooth loss in severe cases. Stop all that right in its tracks with teeth cleaning. With polishing and a fluoride treatment, it is like we give your teeth a headstart with some added protection that can provide lasting benefits. From start to finish, the process usually is about half an hour, depending on how much your teeth need cleaning.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              For most patients, routine teeth cleaning sessions will be quite comfortable. We carefully adapt our cleaning methods to increase your comfort, adjusting as need be in order for you to have no pain or problems during your cleaning session. If you have had negative experiences with other dentists, be sure to let us know and we will take every step we can to make your teeth with us a totally different experience.

            </p>






      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
              Why are cleanings important?
            </h2>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Without routine cleaning, many patients can begin to experience tooth decay despite their good oral health routines. The longer this goes untreated or addressed, the greater a risk it can become. Better to avoid cavities and infections in the first place with a fast and simple routine cleaning. As helpful as brushing and flossing are, they can’t always remove all the bacteria or other buildup on your teeth. That is where teeth cleanings in Corona at Ketcherside Orthodontics comes into play!
            </p>




            <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>
              <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
                Benefits of Teeth Cleaning
              </h2>

              <div class="flex">
                <div class="w-14">
                  <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
                </div>
                <div class="w-full">
                  <h5 class="flex items-center text-xl font-semibold text-purple-900">
                    Remove stubborn buildup of bacteria, plaque, and tartar
                  </h5>

                </div>
              </div>

              <div class="flex">
                <div class="w-14">
                  <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
                </div>
                <div class="w-full">
                  <h5 class="flex items-center text-xl font-semibold text-purple-900">
                    Get your teeth looking better and healthier
                  </h5>

                </div>
              </div>


              <div class="flex">
                <div class="w-14">
                  <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
                </div>
                <div class="w-full">
                  <h5 class="flex items-center text-xl font-semibold text-purple-900">
                    Leave the office feeling fresh and clean
                  </h5>

                </div>
              </div>


              <div class="flex">
                <div class="w-14">
                  <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
                </div>
                <div class="w-full">
                  <h5 class="flex items-center text-xl font-semibold text-purple-900">
                    Fast treatment for lasting results
                  </h5>

                </div>
              </div>

              <div class="flex">
                <div class="w-14">
                  <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
                </div>
                <div class="w-full">
                  <h5 class="flex items-center text-xl font-semibold text-purple-900">
                    Allows your dentist to catch any early signs of decay and prescribe treatment
                  </h5>

                </div>
              </div>

            </div>







            </div>


        </div>
      </div>

    </div>
  </div>

</section>

