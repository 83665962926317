<section class='bg-gradient-to-b from-purple-50 to-yellow-50 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7">
        <span class='inline-block px-4 py-2 font-medium text-blue-900 bg-blue-200 rounded-full shadow-md -rotate-1'>
          Cosmetic Enhancement
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-blue-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Dental Bonding in Corona
      </h1>
      <h2 class="text-2xl text-blue-900 font-semibold">What is Dental Bonding?</h2>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-blue-800 lg:text-left'>
        Dental bonding is a versatile cosmetic procedure where a tooth-colored resin is applied and hardened with a special light, bonding the material to the tooth to restore or improve a person's smile.
      </p>
      <h2 class="text-2xl font-semibold mt-4 text-blue-900">Benefits of Dental Bonding</h2>
      <ul>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Enhances the appearance of teeth</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Repairs chips or cracks</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Closes gaps between teeth</span>
        </li>
        <li class="flex items-center my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Improves the color of teeth</span>
        </li>
      </ul>

    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/bonding.webp" alt="Precision Dental Bonding">
      </div>
    </div>
  </div>
</section>

<section class='bg-gradient-to-b from-yellow-50 to-white px-4 py-16 sm:px-6 lg:px-8 z-auto'>
  <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>

    <h1 class='max-w-4xl mx-auto pb-7 text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
      Q&A
    </h1>
  </div>
  <div class="accordion max-w-2xl mx-auto">
    <div *ngFor="let item of accordionItems" class="mb-4">
      <div class="flex items-center justify-between bg-purple-100 py-8 p-2 cursor-pointer rounded-lg text-xl mx-auto " (click)="toggleItem(item)">
        <span class="mx-auto">{{ item.question }}</span>
        <svg *ngIf="item.open" class="h-4 w-4 fill-current text-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19 9l-7 7-7-7" />
        </svg>
        <svg *ngIf="!item.open" class="h-4 w-4 bg-purple-100 text-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 21l-7-7 1.41-1.41L12 18.17l5.59-5.58L19 14z" />
        </svg>

      </div>
      <div *ngIf="item.open" class="p-4 mx-auto item-center text-lg">
        <p>{{ item.answer }}</p>
      </div>
    </div>
  </div>

</section>

