import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-teeth-whitening',
  standalone: true,
  imports: [],
  templateUrl: './teeth-whitening.component.html',
  styleUrl: './teeth-whitening.component.scss'
})
export class TeethWhiteningComponent {


  title = 'Teeth Whitening in Corona';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Get a brighter smile with our professional teeth whitening in Corona CA. Call (909) 793-1595' }
    );
  }

}
