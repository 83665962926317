@defer (prefetch on idle) {


  <section class="mx-auto bg-gradient-to-b from-purple-50 to-white pt-10 ">
      <div class="mx-auto pb-7 ">
        <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          Corona Orthodontic Price Estimate
        </h1>
        <p class="mx-auto max-w-2xl text-center text-purple-900 text-xl leading-tight tracking-tight  sm:leading-tighter ">

          Disclaimer: The orthodontic pricing calculator provided on this website is intended for informational purposes only and should not be considered as a precise quote. Costs for orthodontic treatment can vary based on individual needs, specific treatment plans, geographic location, and other factors not included here. For a comprehensive and personalized quote please schedule a free orthodontic consultation
        </p>
      </div>
<!-- <div *ngIf="this.nameBool"> -->

      <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
        1. What is your age?
      </h1>
      <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">

        <div class="">
            <input  (change)="onKey($event)" class="inline-block text-4xl text-center py-2 text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShown2e==false,'text-white bg-purple-900': isShown2e==true}"
             aria-current="page">
        </div>
      </div>
<div class=" ">
    <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800  font-bold'>
      2. Are you missing any teeth?
    </h1>


    <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">

      <div class="mr-2 ">
          <button (click)="toggleShowe()" class="inline-block text-4xl px-8 py-2 text-white bg-gray-800 rounded-lg active" [ngClass]="{'bg-purple-100 text-black': isShowne==false,'text-white bg-gray-800': isShowne==true}"
           aria-current="page"> No</button>
      </div>
      <div class="mr-2">
        <button (click)="toggleShow1e()" class="inline-block px-6 py-2 bg-purple-100 text-black text-4xl rounded-lg hover:bg-gray-800 hover:text-white" [ngClass]="{' text-white bg-gray-800': isShown1e==true}"
        aria-current="page">Yes</button>
      </div>
    </div>
      <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
        3. Teeth Crowding
      </h1>
      <ul class="flex mx-auto justify-center flex-wrap text-lg text-white font-medium text-center">
        <li class="mr-2 mb-2">
            <div (click)="toggleShow()" class="inline-block px-2 py-2 text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShown==false,'text-white bg-purple-900': isShown==true}" aria-current="page" style="width: 250px; height: 225px;">
                <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/crowding0.svg' alt="none"> Minor
            </div>
        </li>
        <li class="mr-2 mb-2">
            <button (click)="toggleShow1()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown1==true}" aria-current="page" style="width: 250px; height: 225px;">
                <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/crowding1.svg' alt="mild crowding">Mild
            </button>
        </li>
        <li class="mr-2 mb-2">
            <button (click)="toggleShow2()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown2==true}" aria-current="page" style="width: 250px; height: 225px;">
                <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/crowding2.svg' alt="medium crowding">Medium
            </button>
        </li>
        <li class="mr-2 mb-2">
            <button (click)="toggleShow3()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown3==true}" aria-current="page" style="width: 250px; height: 225px;">
                <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/crowding3.svg' alt="crowding">Severe
            </button>
        </li>
    </ul>

    <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
      4. Do you have a Underbite, Overbite, or Crossbite?
    </h1>


    <ul class="flex mx-auto justify-center flex-wrap text-lg text-white font-medium text-center">
      <li class="mr-2 mb-2">
        <button (click)="toggleShowb()" class="inline-block  py-20 text-4xl text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShownb==false,'text-white bg-purple-900': isShownb==true}"
         aria-current="page" style="width: 250px; height: 225px;"><img class='w-60 text-4xl' loading="lazy">None</button>
    </li>
    <li class="mr-2 mb-2">
        <button (click)="toggleShow1b()" class="inline-block py-3 px-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown1b==true}" aria-current="page" style="width: 250px; height: 225px;">
            <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/overbite.svg' alt="overbite">Overbite
        </button>
    </li>
    <li class="mr-2 mb-2">
        <button (click)="toggleShow2b()" class="inline-block py-3 px-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown2b==true}" aria-current="page" style="width: 250px; height: 225px;">
            <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/underbite1.svg' alt="underbite">Underbite
        </button>
    </li>
    <li class="mr-2 mb-2">
        <button (click)="toggleShow3b()" class="inline-block py-3 px-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown3b==true}" aria-current="page" style="width: 250px; height: 225px;">
            <img style='width: 100%; height: auto;' loading="lazy" src='../../../../assets/svg/Crossbite.svg' alt="crossbite">Crossbite
        </button>
    </li>
</ul>



    <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
      5. Do you have any gaps in your teeth?
    </h1>


    <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">

      <div class="mr-2 mb-2">
          <button (click)="toggleShowc()" class="inline-block px-2 py-2 text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShownc==false,'text-white bg-purple-900': isShownc==true}"
           aria-current="page"> <img class='w-60' loading="lazy" src='../../../../assets/svg/spacingnone.svg' alt="checkmark decoration">No Spacing</button>
      </div>
      <div class="mr-2 mb-2">
        <button (click)="toggleShow1c()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown1c==true}"
        aria-current="page">     <img class='w-60' loading="lazy" src='../../../../assets/svg/mildspacing.svg' alt="checkmark decoration">Mild Spacing</button>
      </div>
      <div class="mr-2 mb-2">
          <button (click)="toggleShow2c()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown2c==true}"
          aria-current="page"> <img class='w-60' loading="lazy" src='../../../../assets/svg/mediumspacing.svg' alt="checkmark decoration">Medium Spacing</button>
      </div>
      <div class="mr-2 mb-2">
          <button (click)="toggleShow3c()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown3c==true}">
            <img class='w-60' loading="lazy" src='../../../../assets/svg/ExtremeSpacing.svg' alt="checkmark decoration">Extreme Spacing</button>
          </div>


    </div>



    <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
      6. Which orthodontic system do you want?
    </h1>


    <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">

      <div class="mr-2 mb-2">
          <button (click)="toggleShowd()" class="inline-block px-2 py-3 text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShownd==false,'text-white bg-purple-900': isShownd==true}"
           aria-current="page"> <img class='w-60' loading="lazy" src='../../../../assets/svg/metalbraces.svg' alt="checkmark decoration">Braces</button>
      </div>
      <div class="mr-2 mb-2">
        <button (click)="toggleShow1d()" class="inline-block px-2 py-3 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown1d==true}"
        aria-current="page">     <img class='w-60' loading="lazy" src='../../../../assets/svg/clearbrace.svg' alt="checkmark decoration">Clear Braces</button>
      </div>
      <div class="mr-2 mb-2">
          <button (click)="toggleShow2d()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown2d==true}"
          aria-current="page"> <img class='w-60' loading="lazy" src='../../../../assets/svg/invisalign.svg' alt="checkmark decoration">Invisalign</button>
      </div>
      <div class="mr-2 mb-2">
          <button (click)="toggleShow3d()" class="inline-block px-2 py-2 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown3d==true}">
            <img class='w-60' loading="lazy" src='../../../../assets/svg/invisalign.svg' alt="checkmark decoration">In-House Aligners</button>
          </div>


    </div>
    </div>







    <h1 class='max-w-4xl pt-20 mx-auto text-center text-purple-900  leading-tight tracking-tight text-4xl sm:leading-tighter font-bold'>
      <button (click)="calcprice()"    class="inline-block px-4 py-3 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white">
        Click for price estimate?</button>
    </h1>

    <div class="py-9">


      <div *ngIf="this.fp" class="max-w-xl mx-auto bg-purple-100 p-8 rounded-xl shadow-md py-4">
        <h1 class="text-2xl font-semibold mb-5 ">Treatment Information</h1>

        <div class="grid grid-cols-2 gap-4">
            <div class="text-gray-800">Length of treatment:</div>
            <div class="text-lg">{{this.finalprice}} months</div>

            <div class="text-gray-800">Total cost:</div>
            <div class="text-lg">${{this.time}}</div>

            <div class="text-gray-800">Downpayment:</div>
            <div class="text-lg">$350</div>

            <div class="text-gray-800">Monthly payments:</div>
            <div class="text-lg">${{this.monthly | number:'1.0-0' }}/month</div>

            <div class="text-gray-800">Third Party Financing as low as:</div>
            <div class="text-lg">${{this.carecredit | number:'1.0-0' }}/month</div>


        </div>
    </div>
<!--
    <h1 *ngIf="this.fp" class='max-w-4xl pt-10 pb-40 mx-auto text-center text-gray-800 text-5xl leading-tight tracking-tight  sm:leading-tighter font-bold'>
      Your Treatment will take <span class="text-blue-700">{{this.finalprice}} months</span>.  The total cost of your treatment will be <span class="text-blue-700">${{this.time}}</span>
    </h1>

    <br>
    <h1 *ngIf="this.fp" class='max-w-4xl pt-10 pb-40 mx-auto text-center text-gray-800 text-5xl leading-tight tracking-tight  sm:leading-tighter font-bold'>
        Your payments will be ${{ this.monthly | number:'1.0-0' }}/per month
        with a $350 downpayment
    </h1>

    <br>
    <h1 *ngIf="this.fp" class='max-w-4xl pt-10 pb-40 mx-auto text-center text-gray-800 text-5xl leading-tight tracking-tight  sm:leading-tighter font-bold'>
        Your new <span class="text-red-900">hello test please work smile will take {{this.finalprice}} months </span>and will cost ${{ this.monthly | number:'1.0-0' }}/per month

    </h1> -->


    </div>
<!-- </div> -->


    </section>



    <section *ngIf="this.fp" class="mx-auto bg-white py-10 ">

      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Located in Corona, CA
      </h1>
   <div class='mt-16 lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
    <iframe class='w-full rounded-3xl'
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.92396507248!2d-117.5556445!3d33.8579374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43116ec56c21e3be!2sKetcherside%20Orthodontics%20Inc.!5e0!3m2!1sen!2sus!4v1664564711858!5m2!1sen!2sus"
      height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>


</section>
}


<!--
      <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
        Name
      </h1>
      <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">
        <div class="">
          <input   [formControl]="nameFormControl" placeholder="Ex. John Smith" [(ngModel)]="this._name" class="inline-block text-xl text-center py-2 text-black bg-purple-200 rounded-lg"
           aria-current="page">
      </div>
      </div>

      <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
        Phone Number
      </h1>
      <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">
        <div class="">
          <input   [formControl]="phoneFormControl" placeholder="9097931595" [(ngModel)]="this._phone" class="inline-block text-xl text-center py-2 text-black bg-purple-200 rounded-lg"
           aria-current="page">
      </div>
      </div>

      <h1 class='max-w-4xl pt-20 pb-4 mx-auto text-4xl  leading-tight text-center text-gray-800 font-bold'>
        Best time to contact you?
      </h1>
      <div class="flex justify-center flex-wrap text-lg text-white font-medium text-center ">
        <div class="">
          <input   placeholder="5 in the evening" [(ngModel)]="this._time" class="inline-block text-xl text-center py-2 text-black bg-purple-200 rounded-lg"
           aria-current="page">
      </div>
      </div>
<div class="mx-auto flex justify-center">
      <div class="form-control mt-10 bg-red-500 rounded-2xl" *ngIf="!submitBtnBool">
        <input type="button" value="Send Form" class="btn btn-secondary font-normal text-base tracking-widest"
          (click)="addInviteDocument()">
      </div>
      <div class="form-control mt-8" *ngIf="submitBtnBool">
        <button type="button" class="btn btn-secondary text-base tracking-widest normal-case font-light"
          [disabled]="submitBtnBool">✔️ Thank you! We will contact you soon.</button>
      </div>
    </div>

    <div>

      <h1>

        hello please
      </h1>
    </div> -->
<!--
    <section class='bg-gradient-to-b from-purple-50 to-white px-28 pt-16 sm:px-6 lg:px-8 z-auto'>
      <div class=' px-8 pt-16 z-auto'>

        <div
          class='relative z-10 w-fit mx-auto   p-14'>
          <div>
            <h3 class='text-2xl font-bold text-purple-900'>
              Send us a message
            </h3>
            <p class='text-purple-800 mt-0.5 text-opacity-90'>
              We'll get back to you within 24 hours.
            </p>
          </div>
      <div class="mx-auto bg-white pt-10 ">
      <form #myForm="ngForm" class="" [class.hover:border-gray-200]="nameFormControl.disabled">
        <mat-form-field class="-my-0 w-full" appearance="legacy">
          <mat-label>Name</mat-label>
          <div class="">
            <input  (change)="onKey($event)" [formControl]="nameFormControl" placeholder="Ex. John Smith" [(ngModel)]="this._name" class="inline-block text-xl text-center py-2 text-black bg-purple-200 rounded-lg"
             aria-current="page">
        </div>


          <mat-error *ngIf="nameFormControl.hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
      <form class="" [class.hover:border-gray-200]="emailFormControl.disabled">
        <mat-form-field class="-my-0 w-full" appearance="legacy">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. person@school.com"
            [(ngModel)]="this._email">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"
            class="mt-0">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
      <form class="" [class.hover:border-gray-200]="phoneFormControl.disabled">
        <mat-form-field class="-my-0 w-full" appearance="legacy">
          <mat-label>Phone</mat-label>
          <input type="number" matInput [formControl]="phoneFormControl" placeholder="Ex. 9091234567"
            [(ngModel)]="this._phone">
          <mat-error *ngIf="phoneFormControl.hasError('pattern') && !phoneFormControl.hasError('required')"
            class="mt-0">
            Please enter a valid phone
          </mat-error>

        </mat-form-field>
      </form>
      </div>
      </div>
      </div>



      <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Send us a message
          </h3>
          <p class='text-purple-800 mt-0.5 text-opacity-90'>
            We'll get back to you within 24 hours.
          </p>
        </div>

        <div class="pt-7"></div>
        <form #myForm="ngForm" class="" [class.hover:border-gray-200]="nameFormControl.disabled">
          <mat-form-field class="-my-0 w-full" appearance="legacy">
            <mat-label>Name</mat-label>
            <input type="text" matInput [formControl]="nameFormControl" placeholder="Ex. John Smith"
              [(ngModel)]="this._name">

            <mat-error *ngIf="nameFormControl.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </form>
        <form class="" [class.hover:border-gray-200]="emailFormControl.disabled">
          <mat-form-field class="-my-0 w-full" appearance="legacy">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. person@school.com"
              [(ngModel)]="this._email">
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"
              class="mt-0">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </form>
        <form class="" [class.hover:border-gray-200]="phoneFormControl.disabled">
          <mat-form-field class="-my-0 w-full" appearance="legacy">
            <mat-label>Phone</mat-label>
            <input type="number" matInput [formControl]="phoneFormControl" placeholder="Ex. 9091234567"
              [(ngModel)]="this._phone">
            <mat-error *ngIf="phoneFormControl.hasError('pattern') && !phoneFormControl.hasError('required')"
              class="mt-0">
              Please enter a valid phone
            </mat-error>

          </mat-form-field>
        </form>
        <form class="" [class.hover:border-gray-200]="messageFormControl.disabled">
          <mat-form-field class="-my-0 w-full" appearance="legacy">
            <mat-label>Message</mat-label>
            <input type="text" matInput [formControl]="messageFormControl" placeholder="Type in your message"
              [(ngModel)]="this._message">

          </mat-form-field>
        </form>
        <div class="form-control mt-8" *ngIf="!submitBtnBool">
          <input type="button" value="Send Form" class="btn btn-secondary font-normal text-base tracking-widest"
            (click)="addInviteDocument()">
        </div>
        <div class="form-control mt-8" *ngIf="submitBtnBool">
          <button type="button" class="btn btn-secondary text-base tracking-widest normal-case font-light"
            [disabled]="submitBtnBool">✔️ Thank you! We will contact you soon.</button>
        </div>
      </div>
    </section>

 -->
