@defer (prefetch on idle) {



<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Trusted and Affordable Care

        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Braces in Corona, CA
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>

      </p>
        <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">               Free braces consultation to asses your teeth
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get personalized treatment based on your unique smile
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Obtain a clear estimate of the costs of your treatment
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Explore payment plans for a low monthly payment
          </span>
        </li>

      </ul>


      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>


        <p class="hidden mx-auto text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of experience <br>
        </p>


      </div>



    </div>


 <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl"   src="../../../../assets/images1/landing_1.webp" alt="Beautiful Smile">

      </div>
    </div>


  </div>


  <div class='flex flex-col items-center mt-20 lg:mt-24  lg:flex '>

    <p class="text-xl  tracking-wider text-purple-900 uppercase">
      Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
    </p>
    <div class='flex flex-col overflow-hidden  sm:flex-row flex-start'>

      <div class='flex flex-col items-center pt-5 '>
        <div class='flex justify-center w-full'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>
        <p class='text-lg  tracking-wide text-purple-700 uppercase'>
          Google Reviews
        </p>

        <div class='mt-10 font-semibold lg:mt-12'>
          <a href="tel:909-341-1678" class='mt-6 text-xl font-medium text-black bg-red-500 sm:mt-0  relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
            Call for a free consultation



          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-10'>
  <div class="text-center mx-auto pb-7">

  </div>
  <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Benefits of Braces
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>

        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">              Improved Oral Health: Easier cleaning and reduced risk of dental issues.
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">              Enhanced Smile and Aesthetics: Boosts self-esteem and confidence.
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">              Correct Bite Issues: Improves jaw alignment and reduces strain
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">             Prevention of Dental Problems: Guards against future oral health issues.
          </span>
        </li>

      </ul>
    </div>
    <div class='max-w-2xl lg:ml-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>

        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">             Customizable Options: Various types of braces to fit your lifestyle.
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">              Long-term Results: Lasting smiles with proper care.
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">              Psychological Benefits: Positive impact on social interactions and opportunities.
          </span>
        </li>

      </ul>
    </div>
  </div>
  <div class='max-w-xl mx-auto lg:max-w-screen-xl text-center'>
    <p class='mt-10 text-lg text-purple-800'>
      At Ketcherside Orthodontics Inc, we're committed to enhancing your smile and improving your oral health. Start your journey with us today!
    </p>
  </div>
</section>

<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-gradient-to-b from-purple-100 to-yellow-50 sm:pb-24 sm:px-6 lg:px-8 outline-1 pt-10'>
  <div class="mx-auto pb-7 text-center">

  </div>
  <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Pricing and financing
  </h1>

  <!-- Flex container for paragraphs and titles -->
  <div class="lg:flex justify-center mx-auto">
    <div class='max-w-2xl mt-3 lg:mr-4'>
      <h2 class='text-center text-purple-800 text-3xl font-semibold'>Braces Pricing</h2>
      <p class='text-xl text-center leading-loose text-purple-800 sm:mt-4'>
        Braces usually cost anywhere between $1800 and $5500 but the exact price for braces is dependent upon the complexity and length of your treatment. Braces are the most budget-friendly orthodontic treatment while also providing the best results.
      </p>
    </div>

    <div class='max-w-2xl mt-3 lg:ml-4'>
      <h2 class='text-center text-purple-800 text-3xl font-semibold'>Insurance and Financing</h2>
      <p class='text-xl text-center leading-loose text-purple-800 sm:mt-4'>
        We accept care credit, all PPO dental insurances and offer in-house financing to provide a low monthly payment for orthodontic treatments. Dental insuraances could cover some of your orthodontic treatment to find out schedule a free orthodontic consultation and we can answer any questions you might have.
      </p>
    </div>
  </div>

  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
    <!-- Enroll CTA -->
    <div class='mt-10 font-semibold lg:mt-12 mx-auto text-center'>
      <a href="tel:9093411678" class='mt-6 text-lg font-medium text-black bg-yellow-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
        Call Now
      </a>
    </div>
  </div>
</section>












<section class='px-4 -mt-8 bg-yellow-50 sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>
  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      Orthodontic Treatment: What to Expect
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          Starting Your Orthodontic Journey
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Embarking on orthodontic treatment is a significant step towards improving dental health and achieving a confident smile. Your journey begins with a comprehensive assessment where we discuss your goals, examine your oral health, and develop a tailored treatment plan. Our friendly team ensures you understand each step and feel comfortable with the process.
        </p>

        <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
          Types of Braces and Appliances
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          We offer a variety of orthodontic solutions, including traditional metal braces, ceramic braces, and clear aligners. Each option has its unique benefits, and we'll help you choose the one that best fits your lifestyle, aesthetic preferences, and treatment needs. Our aim is to provide effective, comfortable, and discreet treatment options.
        </p>
      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          Life with Braces
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Living with braces involves some adjustments, but it's easier than you might think. We provide comprehensive guidance on oral hygiene, diet, and managing minor discomfort. Regular check-ups and adjustments are part of the process, ensuring your treatment stays on track.
        </p>

        <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
          After Treatment: Maintaining Your Smile
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Once your braces are removed, we continue supporting you to maintain your beautiful new smile. Retainers play a crucial role in preserving the alignment of your teeth. We'll provide personalized instructions and ongoing care to ensure your smile stays straight and healthy for years to come.
        </p>
      </div>

    </div>
  </div>
</section>


}