import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-dental-exam',
  standalone: true,
  imports: [],
  templateUrl: './dental-exam.component.html',
  styleUrl: './dental-exam.component.scss'
})
export class DentalExamComponent {


  title = 'Dental Exams | Dentist in Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Keep your dental health on the right track with a dental exam in Corona, CA. Contact our office to schedule an appointment.' }
    );
  }
}
