
<section class='bg-gradient-to-b from-purple-50 to-white px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Discover a Brighter Smile
        </span>
      </div>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
         Corona Dentist
      </h1>
      <p class='text-xl leading-loose text-purple-800 max-w-2xl mt-3 text-center lg:text-left'>
        Providing a full range of dental services tailored to meet the unique needs of each smile.
      </p>
      <ul>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Thorough Oral Health Assessment: Ensuring comprehensive care for your teeth and gums.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Customized Treatment Plans: Tailored to address your specific dental concerns and goals.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Transparent Cost Estimates: Clear and upfront information about the cost of treatments.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Flexible Payment Options with Carecredit: Making dental care accessible to all budgets.</span>
        </li>

      </ul>

    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/about5.webp" alt="Comprehensive Dental Care">
      </div>
    </div>
  </div>
</section>



<section class='relative w-full px-4 py-16 sm:py-24 lg:py-32 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>

    <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
      <div class='flex items-center'>
        <h2 class='max-w-4xl text-purple-900 h2'>
          Services:
        </h2>
      </div>
      <div class='flex items-center mt-6 lg:mt-0'>
        <p class='text-xl leading-relaxed text-purple-800'>
          Below is a list of of our dental services. Don’t see a service listed? Give us a call at <br>(909) 793-1595 to confirm.
        </p>
      </div>
    </div>

    <div class='grid gap-8 mt-12 sm:max-w-none sm:grid-cols-2 sm:mt-14 lg:mt-24 lg:grid-cols-4 sm:gap-6 xl:gap-12'>

      <div class='flex flex-col text-lg items-center justify-center px-4 py-6 bg-yellow-200 sm:p-8 sm:py-10 rounded-2xl'>
        <h4>
          Bonding<br>
          Bone Grafting <br>
          Bridges<br>
          Crowns<br>
          Dental Exams<br>



        </h4>


      </div>

      <div class='flex flex-col text-lg items-center justify-center px-4 py-6 bg-yellow-200 sm:p-8 sm:py-10 rounded-2xl'>
        <h4>
          Dental Implant Surgery<br>
          Dental Implants<br>
          Extractions<br>
          Fillings<br>
          Flouride treatment<br>


        </h4>

      </div>

      <div class='flex flex-col text-lg items-center justify-center px-4 py-6 bg-yellow-200 sm:p-8 sm:py-10 rounded-2xl'>
        <h4>
          Implant Restoration<br>
          Mouthguards<br>
          Nightguards<br>
          Scaling and Root Planning<br>
          Sealants<br>
        </h4>

      </div>

      <div class='flex flex-col text-lg items-center justify-center px-4 py-6 bg-yellow-200 sm:p-8 sm:py-10 rounded-2xl'>
        <h4>
          Teeth Cleaning<br>
          Teeth Whitening<br>
          Tooth Colored Fillings<br>
          Veneers<br>
          X-Rays<br>
        </h4>

      </div>

    </div>

  </div>

</section>




<section class='px-4 -mt-8  bg-white sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      Affordable Options
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
            Creating a Long Term Oral Health Plan
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          A treatment plan and plan options will be designed for you based on your comprehensive exam, intraoral X-rays, professional cleaning and polishing, oral hygiene instructions, oral cancer screening, and periodontal diagnosis. Whether you decide to proceed with a definitive treatment plan via comprehensive dentistry or sequence treatment in the future, you will leave our office with the sure knowledge of your mouth’s overall health.
        </p>



        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Providing you with the highest quality of dental care on a sound business basis is our goal. Our doctors and team are sensitive to your budget, and offer a variety of payment plans and financing options to help you get the dentistry you need at a cost you can afford.
                </p>

        <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>
          <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
            Dedicated to Your Safety
          </h2>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
            Your health and safety are at the forefront of our doctors’ and staff’s minds at all times. Our practice takes pride in the following to ensure your overall well-being:
                            </p>


        </div>


      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
              Insurance
            </h2>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Our office staff is always available to help you with insurance claims and paperwork. We’ll work with your insurance provider to make sure your coverage meets your needs and your budget.            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              During your initial consultation at our Corona office, our team will review your coverage and explain your benefits to you.
                        </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Once treatment begins, we will gladly submit and file your insurance claim on your behalf to make sure your benefits are maximized. To expedite your insurance filing, please be sure to provide your insurance information before your first consultation.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Please remember that dental insurance is a benefit for you or your child. If your benefits change anytime during treatment, the financially responsible party is accountable for all charges.
                        </p>





            </div>

            <div class='flex flex-col justify-center pr-10 xl:pr-0'>

              <h2 class='mt-3.5 sm:mt-4 h2 max-w-xl text-[#4a499c]'>
                Our Financing Plans
              </h2>
              <p class='max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0'>
                We offer flexible payment plans through CareCredit. Talk to our office about the financing plan that best fits your budget.        </p>

              <div class='mt-8 font-medium lg:mt-10'>
                <p class='text-purple-800'>Want to learn more about our programs?</p>
                <div class='flex  mt-8'>
                  <a href="tel:9097931595" class='mt-6 text-lg font-medium text-black bg-yellow-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
                    Call Now



                  </a>
                </div>
              </div>
            </div>


        </div>
      </div>

    </div>
  </div>

</section>



