import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-orthodontics',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './orthodontics.component.html',
  styleUrl: './orthodontics.component.scss'
})
export class OrthodonticsComponent {

  title = 'Orthodontist in Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Ketcherside Orthodontics in Corona, CA, specializes in braces, clear braces, Invisalign, and more. Schedule your free consultation today!' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@type": "Service",
          "serviceType": "Orthodontic Services in Corona, CA",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Ketcherside Orthodontics",
            "areaServed": {
              "@type": "City",
              "name": "Corona"
            },
            "category": "Orthodontist",
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Orthodontic Services",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Braces"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Clear Braces"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Invisalign"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Teen Invisalign"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Adult Braces"
                  }
                }
              ]
            },
            "openingHours": "Mo-Fr 9:00-17:00",
            "telephone": "+19097931595",
            "email": "drgaryk1@gmail.com",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Corona",
              "addressRegion": "CA",
              "postalCode": "92879",
              "streetAddress": "720 Magnolia Ave Suite #1"
            },
            "paymentAccepted": "Cash, Credit Card",
            "priceRange": "$$",
            "image": "https://ketchersideortho.com/assets/images1/logo1.svg"
          }
        }
        `;

        this._renderer2.appendChild(this._document.body, script);

  }
}
