import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-wisdomteeth',
  standalone: true,
  imports: [],
  templateUrl: './wisdomteeth.component.html',
  styleUrl: './wisdomteeth.component.scss'
})
export class WisdomteethComponent {

  title = 'Wisdom Teeth Extractions in Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Have your impacted wisdom teeth removed by Corona CA Oral Surgeons. Start with an oral exam. 206-522-2212.!' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@context": "http://schema.org",
          "@type": "Service",
          "serviceType": "Orthodontic Treatment",
          "name": "Invisalign",
          "description": "Invisalign offers a discreet, comfortable, and effective way to straighten your teeth using custom-made, clear aligners. Ideal for adults and teens seeking a more aesthetic alternative to traditional braces.",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Ketcherside Orthodontics",
            "areaServed": {
              "@type": "City",
              "name": "Corona"
            },
            "category": "Orthodontics",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "720 Magnolia Ave Suite #1",
              "addressLocality": "Corona",
              "addressRegion": "CA",
              "postalCode": "92879"
            },
            "telephone": "+19097931595",
            "email": "drgaryk1@gmail.com",
            "image": "https://ketchersideortho.com/assets/images1/logo1.svg"
          },
          "areaServed": {
            "@type": "Place",
            "name": "Corona, CA"
          },
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Invisalign Treatment Options",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Full Treatment"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Teen Treatment"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Express Treatment"
                }
              }
            ]
          },
          "openingHours": "Mo-Fr 9:00-17:00",
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$"
        }

        `;

        this._renderer2.appendChild(this._document.body, script);

  }
}
