<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-blue-700 bg-blue-200 rounded-full shadow-md -rotate-1'>
          Expert Wisdom Teeth Care
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-blue-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold '>
        Wisdom Teeth Extractions in Corona
      </h1>
      <p class=' text-xl leading-loose text-center text-blue-800 justify-center max-w-3xl mx-auto  lg:max-w-none lg:col-span-6'>
        Expert care for a comfortable and safe extraction experience.
      </p>
        <ul class='flex flex-col justify-center max-w-3xl mx-auto lg:max-w-none lg:col-span-6'>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Comprehensive evaluation of your wisdom teeth</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Personalized extraction plan tailored to your needs</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Detailed explanation of the procedure and aftercare</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Flexible payment options and insurance assistance</span>
        </li>
      </ul>

    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/wisdomteeth.webp" alt="Wisdom Teeth Removal">
      </div>
    </div>
  </div>
</section>


<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-24'>
  <h1 class='text-4xl text-blue-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Reasons for Wisdom Teeth Removal
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-blue-800 mx-auto pl-5'>
        <li class="flex items-center my-2">
          <span class="text-lg">Prevention of Overcrowding: Avoids misalignment of existing teeth.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Reduces Risk of Oral Disease: Helps prevent gum disease and tooth decay.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Relieves Pain and Discomfort: Alleviates issues caused by impacted teeth.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Improves Oral Hygiene: Easier cleaning of teeth and gums.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Enhances Overall Health: Reduces potential for systemic health issues linked to oral health.</span>
        </li>
      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-blue-800'>
      Our dedicated team at Ketcherside Orthodontics Inc uses the latest techniques for a safe and effective wisdom teeth removal experience.
    </p>
  </div>
</section>

<section loading="lazy" class='px-4 pb-12 overflow-hidden bg-purple-100 lg:pt-24 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-32 lg:max-w-screen-xl '>

    <div class='py-16 lg:py-32'>
      <div>

      </div>
      <h1 class='max-w-md mt-4 text-purple-900 h1'>
        Get A Consultation
      </h1>
      <p class='max-w-lg mt-3 text-xl leading-relaxed text-purple-800'>
        Are you in need of wisdom teeth extractions or a dental consulation? Look no further! Our dental clinic is now offering consultation services to address all your dental concerns. Fill out the form and we'll contact you as soon as possible.


      </p>
    </div>

    <div class='relative'>



      <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Contact Form
          </h3>

        </div>

        <div class="pt-7"></div>
        <form action="https://formspree.io/f/xoqolkjo" method="POST" class="flex flex-col space-y-4 bg-white p-4 shadow-md rounded-lg">

          <label class="block">
            <span class="text-gray-700">Your name:</span>
            <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
          </label>
          <!-- Email Input -->
          <label class="block">
            <span class="text-gray-700">Your email:</span>
            <input type="email" name="email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="you@example.com">
          </label>

          <!-- Phone Number Input -->
          <label class="block">
            <span class="text-gray-700">Phone number:</span>
            <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="123-456-7890">
          </label>

          <!-- Message Input -->
          <label class="block">
            <span class="text-gray-700">Your message:</span>
            <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
          </label>

          <!-- Submit Button -->
          <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Send
          </button>
        </form>

      </div>

    </div>

  </div>

</section>
