@defer (prefetch on idle) {


<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Free Consultation
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Corona Clear Aligners
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>

      </p>
        <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">               Free smile exam to assess your teeth and bite
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get personalized treatment based on your unique smile
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Obtain a clear estimate of the costs of your aligner treatment
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Explore payment plans that fit your budget
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get an additional $250 off clear aligners
          </span>
        </li>
      </ul>
      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>
        <p class="hidden mx-auto text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of experience <br>
        </p>
      </div>
    </div>
 <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl"  src="../../../../assets/images1/invisalign_1.webp" alt="Beautiful Smile">

      </div>
    </div>
  </div>
</section>
<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-24'>
  <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Benefits of Clear Aligners in Corona
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Nearly Invisible Aligners: Discreet treatment with clear aligners.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Removable for Flexibility: Easily take them out for eating and cleaning.</span>
        </li>


        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Less Time-Consuming: Fewer visits to the orthodontist required.</span>
        </li>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Boost in Self-Confidence: A straighter smile to boost your self-esteem.</span>
        </li>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Ideal for Active Lifestyles: Safer for sports and physical activities.</span>
        </li>

      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-purple-800'>
      At Ketcherside Orthodontics in Corona, we combine advanced technology with personalized care to give you the best clear aligners experience. Start your journey towards a perfect smile today!
    </p>
  </div>
</section>



<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-gradient-to-b from-purple-100 to-yellow-50 sm:pb-24 sm:px-6 lg:px-8 pt-10'>
  <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Aligners Pricing and Financing
  </h1>
  <div class="lg:flex justify-center mx-auto mt-3">
    <!-- Pricing Section -->
    <div class='max-w-2xl lg:mr-4'>
      <h2 class='text-3xl text-purple-800 font-semibold text-center'>Clear Aligner Pricing</h2>
      <p class='text-xl text-center leading-loose text-purple-800 sm:mt-4'>
        Aligners treatment in Corona typically ranges from $2500 to $7000, depending on the complexity of the case. Our clinic offers competitive pricing and flexible payment options for aligners, making it an accessible choice for many.
      </p>
    </div>
    <!-- Financing Section -->
    <div class='max-w-2xl lg:ml-4'>
      <h2 class='text-3xl text-purple-800 font-semibold text-center'>Insurance and Financing Options</h2>
      <p class='text-xl text-center leading-loose text-purple-800 sm:mt-4'>
        At Ketcherside Orthodontics, we accept various dental insurances and offer in-house financing to make Clear Aligners treatment more affordable. Schedule a free consultation to explore your options and find a plan that works best for you.
      </p>
    </div>
  </div>
  <!-- Call to Action -->
  <div class='mt-10 lg:mt-12 text-center mx-auto max-w-xl lg:max-w-screen-xl'>
    <a href="tel:9093411678" class='inline-flex items-center justify-center px-8 py-3 mt-6 text-lg font-medium text-black bg-yellow-300 rounded-full hover:bg-yellow-500 hover:text-black duration-300 ease-in-out sm:mt-0 sm:ml-6'>
      Call Now for a Consultation
    </a>
  </div>
</section>





<section class="bg-yellow-50 h-auto pb-10 pt-10">

  <div class='flex flex-col justify-center align-middle max-w-3xl mx-auto lg:w-1/2 w-3/4 lg:mt-0 lg:max-w-none lg:col-span-6'>
    <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Send us a message
          </h3>
          <p class='text-purple-800 mt-0.5 text-opacity-90'>
            We'll get back to you as soon as possible
          </p>
        </div>

        <div class="pt-7">
          </div>



          <form action="https://formspree.io/f/xoqolkjo" method="POST" class="flex flex-col space-y-4 bg-white p-4 shadow-md rounded-lg">

            <label class="block">
              <span class="text-gray-700">Your name:</span>
              <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
            </label>
            <!-- Email Input -->
            <label class="block">
              <span class="text-gray-700">Your email:</span>
              <input type="email" name="email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="you@example.com">
            </label>

            <!-- Phone Number Input -->
            <label class="block">
              <span class="text-gray-700">Phone number:</span>
              <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="123-456-7890">
            </label>

            <!-- Message Input -->
            <label class="block">
              <span class="text-gray-700">Your message:</span>
              <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
            </label>

            <!-- Submit Button -->
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Send
            </button>
          </form>
        </div>
  </div>


</section>






<section class='px-4 -mt-8  bg-yellow-50 sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      A modern approach
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          Perfect for Busy Schedules
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          At Ketcherside Orthodontics, you can get a great smile without a treatment that gets in the way of your daily life. The best part about the whole process is that most people won’t even know that you’re straightening your teeth.
        </p>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

            This nearly-invisible option to straighten your teeth is truly changing the way people see orthodontic treatment in Corona.
          </p>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">


            Clear Aligners takes a modern approach to straightening teeth. It uses a custom-made series of aligners created for you and only you. Wearing the aligners will gently move your teeth into place little by little, based on the exact movements Dr. Ketcherside plans for you.           </p>

        <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>
          <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
            Able to Correct Many Cases
          </h2>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                crowded teeth
              </h5>

            </div>
          </div>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                spaced teeth
              </h5>

            </div>
          </div>


          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                deep bite
              </h5>

            </div>
          </div>


          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                open bite
              </h5>

            </div>
          </div>





        </div>

        <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
          Comparable Cost to Traditional Braces
        </h2>


        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

          Many factors determine the cost of aligner treatment, and at Ketcherside Orthodontics, we understand that every person’s needs are different. In most cases, the cost of aligners is comparable to traditional braces and covered by most dental insurance plans.
        </p>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

          Financing options are also available to make aligner more affordable for your family. The Ketcherside Orthodontics team is always here to answer any questions regarding financing, payment plans, and insurance claims.
          </p>

      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
            </h2>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              There are no metal brackets to attach and no wires to tighten. You just pop in a new set of aligners about every two weeks until your treatment is complete.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Aligners has vastly improved over the years and can be used to handle various of simple and complex cases. From crowding to spacing, underbite to overbite, aligners can help you obtain your ideal smile.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">



              Dr. Ketcherside has years of experience treating patients with clear aligners, and we’re excited to offer this technology to our Corona orthodontic patients.                 </p>


              <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
                Easier to Keep Clean
              </h2>


              <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

                Aligners with Ketcherside Orthodontics are often more hygienic than braces because it is much easier to clean your teeth when there are no braces in the way.
              </p>
              <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

                This also helps your teeth stay clean and white throughout treatment with reduced risk of demineralization..
                </p>






            <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
              Responsibilities with Aligners
            </h2>


            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              It’s important to wear your aligners for at least 22 hours each day as directed by Dr. Ketcherside for them to do their job and straighten your teeth. But, with clear aligners, you can remove your aligners for special occasions.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">



              Like with traditional braces, Dr. Ketcherside will provide a retainer after clear aligner treatment to maintain your new alignment—and your confident new smile.              </p>

            </div>


        </div>
      </div>

    </div>
  </div>

</section>








}