@defer (prefetch on idle) {

<section class='px-4 overflow-hidden bg-white pb-28 sm:pb-36 sm:px-6 lg:px-8'>
  <div class='max-w-screen-xl mx-auto'>
    <div class="px-4 pt-32 text-center sm:px-6 lg:px-8">
      <h2 class="text-purple-900 text-6xl font-semibold pb-6">
        Corona Orthodontist
      </h2>
			<h3 class="text-purple-900 text-4xl">
        <span class="block"> </span>

        <span class="relative block">
          <span class='relative'>
            <img class='absolute inset-0 transform translate-y-9 sm:translate-y-10 xl:translate-y-6' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline">
            <span class='relative'>Treatment Options</span>
          </span>
        </span>

      </h3>
		</div>
    <div class="grid mt-20 gap-14 max-w-xl mx-auto sm:mt-24 lg:mt-44 sm:gap-16 lg:grid-cols-12 lg:max-w-none lg:gap-8">
      <!-- Braces Section -->
      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:order-1 lg:text-left">
        <div>
          <span class='px-4 py-2 inline-block text-purple-700 bg-purple-200 rounded-full shadow-md font-medium -rotate-1'>
            Traditional Braces
          </span>
        </div>
        <div>
          <h3 class="mt-3.5 text-purple-900 text-3xl font-bold">
            Braces
          </h3>
          <p class="mt-3 max-w-xl text-lg text-purple-800 sm:leading-relaxed sm:text-xl">

            Traditional braces are a time-tested orthodontic solution, featuring brackets attached to each tooth, connected by wires. They are particularly effective for correcting complex dental issues and offer a high level of precision in teeth alignment. The main advantage is their cost-effectiveness, making them an accessible choice for many. However, they are more noticeable than other options and require careful cleaning. Ideal for patients seeking an affordable and reliable solution for significant dental corrections.
          </p>
          <a href='/braces' class="mt-10 inline-block p-2 font-medium rounded-full text-white bg-purple-600 btn-sm hover:bg-purple-500">
            Learn More About Braces
          </a>
        </div>
      </div>
      <div class="hidden lg:flex relative order-1 lg:col-span-6 lg:order-2 lg:items-center">
        <div class="absolute inset-0 w-full h-full transform scale-150 hidden lg:block">
          <img src='../../../../assets/images1/blob-light-yellow.svg' class='w-full h-full object-cover' alt='Yellow blob decoration'>
        </div>
        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 -translate-x-1/2 -translate-y-8 left-1/2 hidden lg:block' alt='Large dots decoration'>
        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg">
          <figure>
            <img src='../../../../assets/images1/braces_1.webp' class='w-full h-full object-cover shadow-xl rounded-3xl' alt='Teen with braces'>
          </figure>
        </div>
      </div>
      <!-- Additional sections follow similar pattern -->

    </div>
    <div class="grid mt-20 gap-14 max-w-xl mx-auto sm:mt-24 lg:mt-60 sm:gap-16 lg:grid-cols-12 lg:max-w-none lg:gap-8">
      <div class="hidden lg:flex relative order-1 lg:col-span-6 lg:items-center">
        <div class="absolute inset-0 w-full h-full transform scale-150 hidden lg:block">
          <img src='../../../../assets/images1/blob-light-rose.svg' class='w-full h-full object-cover' alt='Rose blob decoration'>
        </div>
        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 -translate-x-1/2 -translate-y-8 left-1/2 hidden lg:block' alt='Large dots decoration'>
        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg">
          <figure>
            <img src='../../../../assets/images1/braces_3.webp' class='w-full h-full object-cover shadow-xl rounded-3xl' alt='Teen Invisalign'>
          </figure>
        </div>
      </div>
      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:order-2 lg:text-left">
        <div>
          <span class='px-4 py-2 inline-block text-purple-700 bg-purple-200 rounded-full shadow-md font-medium -rotate-1'>
            Affordable & Discrete
          </span>
        </div>
        <div>
          <h3 class="mt-3.5 text-purple-900 text-3xl font-bold">
            Clear Braces
          </h3>
          <p class="mt-3 max-w-xl text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Clear braces function similarly to traditional braces but stand out due to their less noticeable appearance. They use tooth-colored or clear brackets, blending more seamlessly with your teeth. While offering the same effectiveness as traditional braces, they provide an aesthetic advantage, making them suitable for patients who are conscious about the visibility of braces. The cost is slightly higher than traditional braces, and they require diligent maintenance to prevent staining. They are perfect for those who value aesthetics alongside effective treatment.

          </p>
          <a href='/clearbraces' class="mt-10 inline-block p-2 font-medium rounded-full text-white bg-purple-600 btn-sm hover:bg-purple-500">
            Learn More About Clear Braces
          </a>
        </div>
      </div>
    </div>

    <div class="grid mt-20 gap-14 max-w-xl mx-auto sm:mt-24 lg:mt-44 sm:gap-16 lg:grid-cols-12 lg:max-w-none lg:gap-8">
      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:order-1 lg:text-left">
        <div>
          <span class='px-4 py-2 inline-block text-purple-700 bg-purple-200 rounded-full shadow-md font-medium -rotate-1'>
            Discrete
          </span>
        </div>
        <div>
          <h3 class="mt-3.5 text-purple-900 text-3xl font-bold">
            Teen Invisalign
          </h3>
          <p class="mt-3 max-w-xl text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
           Teen Invisalign caters specifically to the orthodontic needs of teenagers. It shares the benefits of standard Invisalign — being virtually invisible and removable — but is tailored for the developing mouths of younger patients. Teen Invisalign aligners also come with compliance indicators to help track usage. This treatment is suitable for teenagers who are self-conscious about wearing braces and are disciplined in wearing aligners consistently. It's an excellent option for teens needing mild to moderate dental correction who want to maintain an active and social lifestyle without the hassles of traditional braces.

          </p>
          <a href='/teeninvisalign' class="mt-10 inline-block p-2 font-medium rounded-full text-white bg-purple-600 btn-sm hover:bg-purple-500">
            Learn More About Teen Invisalign          </a>
        </div>
      </div>
      <div class="hidden lg:flex relative order-1 lg:col-span-6 lg:order-2 lg:items-center">
        <div class="absolute inset-0 w-full h-full transform scale-150 hidden lg:block">
          <img src='../../../../assets/images1/blob-light-purple.svg' class='w-full h-full object-cover' alt='Purple blob decoration'>
        </div>
        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 -translate-x-1/2 -translate-y-8 left-1/2 hidden lg:block' alt='Large dots decoration'>
        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg">
          <figure>
            <img src='../../../../assets/images1/braces_2.webp' class='w-full h-full object-cover shadow-xl rounded-3xl' alt='Clear braces image'>
          </figure>
        </div>
      </div>
    </div>


    <div class="grid mt-20 gap-14 max-w-xl mx-auto sm:mt-24 lg:mt-44 sm:gap-16 lg:grid-cols-12 lg:max-w-none lg:gap-8">
      <div class="hidden lg:flex relative order-1 lg:col-span-6 lg:items-center">
        <div class="absolute inset-0 w-full h-full transform scale-150 hidden lg:block">
          <img src='../../../../assets/images1/blob-light-rose.svg' class='w-full h-full object-cover' alt='Rose blob decoration'>
        </div>
        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 -translate-x-1/2 -translate-y-8 left-1/2 hidden lg:block' alt='Large dots decoration'>
        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg">
          <figure>
            <img src='../../../../assets/images1/braces_3.webp' class='w-full h-full object-cover shadow-xl rounded-3xl' alt='Teen Invisalign'>
          </figure>
        </div>
      </div>
      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:order-2 lg:text-left">
        <div>
          <span class='px-4 py-2 inline-block text-purple-700 bg-purple-200 rounded-full shadow-md font-medium -rotate-1'>
            Invisible solutions
          </span>
        </div>
        <div>
          <h3 class="mt-3.5 text-purple-900 text-3xl font-bold">
            Invisalign
          </h3>
          <p class="mt-3 max-w-xl text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Invisalign offers a modern approach to teeth straightening with a series of custom-made, clear aligners. These aligners are virtually invisible, removable, and more comfortable than traditional braces. They allow for easier cleaning of teeth and no dietary restrictions. Although Invisalign is less effective for severe orthodontic issues, it's an excellent choice for patients with mild to moderate alignment problems who prefer a discreet solution. The treatment requires discipline to wear the aligners as prescribed. Ideal for adults and professionals seeking an unobtrusive orthodontic treatment.
          </p>
          <a href='/invisalign' class="mt-10 inline-block p-2 font-medium rounded-full text-white bg-purple-600 btn-sm hover:bg-purple-500">
            Learn More About Invisalign
          </a>
        </div>
      </div>
    </div>
  </div>
</section>



<section class='relative w-full px-4 py-16 bg-[#4a499c] sm:py-24 sm:px-6 lg:px-8'>
  <div class='max-w-screen-xl mx-auto'>
    <div>
      <h2 class="max-w-4xl mx-auto text-center text-white h2">
        Giving your family the best dental and orthodontic care
      </h2>
      <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-50">
        You can count on our exceptional team for high-quality care. The top priority of our team is to provide happy, healthy smiles in a friendly environment      </p>
    </div>
    <div class='mt-12 sm:grid sm:grid-cols-3 sm:gap-x-6 md:gap-x-8 lg:gap-x-12 sm:gap-y-12 gap-y-16'>
      <div class='text-center'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>25,839
        </h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Smiles Changed</p>
      </div>
      <div class='mt-12 text-center sm:mt-0'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>50+</h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Years of Combined Experience</p>
      </div>
      <div class='mt-12 text-center sm:mt-0'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>5 star</h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Google Rating</p>
      </div>


    </div>

  </div>

</section>







<section class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>

}