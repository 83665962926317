import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ortho-pricing',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ortho-pricing.component.html',
  styleUrl: './ortho-pricing.component.scss'
})
export class OrthoPricingComponent {

  price: number = 3000;
  crowdingprice: number = 6;
  overbiteprice: number = 0;
  finalprice: number = 0;
  spacingprice: number = 0;
  aligner: number = 0;
  babyteeth: number = 0;
  missingteeth: number = 0;
  age: number = 24;
  agemodifier: number = 3;
  time: number = NaN;
  monthly: number = NaN;
  carecredit: number = NaN;

  show: any;
  fp: boolean = false

  agebool: boolean = false;
  isShown: boolean = true ; // hidden by default\
  isShown1: boolean = false ; // hidden by default
  isShown2: boolean = false ; // hidden by default
  isShown3: boolean = false ; // hidden by default

  isShownb: boolean = true ; // hidden by default\
  isShown1b: boolean = false ; // hidden by default
  isShown2b: boolean = false ; // hidden by default
  isShown3b: boolean = false ; // hidden by default

  isShownc: boolean = true ; // hidden by default\
  isShown1c: boolean = false ; // hidden by default
  isShown2c: boolean = false ; // hidden by default
  isShown3c: boolean = false ; // hidden by default


  isShownd: boolean = true ; // hidden by default\
  isShown1d: boolean = false ; // hidden by default
  isShown2d: boolean = false ; // hidden by default
  isShown3d: boolean = false ; // hidden by default


  isShowne: boolean = true ; // hidden by default\
  isShown1e: boolean = false ; // hidden by default
  isShown2e: boolean = false ; // hidden by default
  isShown3e: boolean = true ; // hidden by default




  onKey(event: any) { // without type info
    this.age = event.target.value;
    if(this.agebool == true){

      window.location.reload();
    }
    this.agebool = true

    if(this.age < 11) {
      this.agemodifier = 1
    }
    if(this.age <19 && this.age >= 11){
      this.agemodifier = 2
    }

    if(this.age>=19){
      this.agemodifier= 3
    }


  }

//crowding
  toggleShow() {
  this.isShown = true
  this.isShown1 = this.isShown2 = this.isShown3 = false
  if(this.agemodifier == 1){
    this.crowdingprice=6
  }
  if(this.agemodifier == 2){
    this.crowdingprice=8
  }
  if(this.agemodifier == 3){
    this.crowdingprice=10
  }}
toggleShow1() {
  this.isShown1 = true
  this.isShown = this.isShown2 = this.isShown3 = false
  if(this.agemodifier == 1){
    this.crowdingprice=8
  }
  if(this.agemodifier == 2){
    this.crowdingprice=10
  }
  if(this.agemodifier == 3){
    this.crowdingprice=12
  }

}
toggleShow2() {
  this.isShown2 = true
  this.isShown1 = this.isShown = this.isShown3 = false
  if(this.agemodifier == 1){
    this.crowdingprice=14
  }
  if(this.agemodifier == 2){
    this.crowdingprice=16
  }
  if(this.agemodifier == 3){
    this.crowdingprice=18
  }}
toggleShow3() {
  this.isShown3 = ! this.isShown3;
  this.isShown1 = this.isShown2 = this.isShown = false

  if(this.agemodifier == 1){
    this.crowdingprice=24
  }
  if(this.agemodifier == 2){
    this.crowdingprice=26
  }
  if(this.agemodifier == 3){
    this.crowdingprice=28
  }

}

//overbite
toggleShowb() {
  this.isShownb = true
  this.isShown1b = this.isShown2b = this.isShown3b = false
  this.overbiteprice = 0
}
toggleShow1b() {
  this.isShown1b = true
  this.isShownb = this.isShown2b = this.isShown3b = false
  if(this.agemodifier > 1){
    this.overbiteprice=24
  }
  else{
    this.overbiteprice = 12
  }
}
toggleShow2b() {
  this.isShown2b = true
  this.isShown1b = this.isShownb = this.isShown3b = false
  if(this.agemodifier > 1){
    this.overbiteprice=24
  }
  else{
    this.overbiteprice = 14
  }}
toggleShow3b() {

  this.isShown3b = true
  this.isShown1b = this.isShown2b = this.isShownb = false
  if(this.agemodifier == 2){
    this.overbiteprice=18
  }
  if(this.agemodifier == 3){
    this.overbiteprice=24
  }
  if(this.agemodifier == 1){
    this.overbiteprice=12
  }
}

//spacing
toggleShowc() {
  this.isShownc = true
  this.isShown1c = this.isShown2c = this.isShown3c = false
  this.spacingprice = 0
}
toggleShow1c() {
  this.isShown1c = true
  this.isShownc = this.isShown2c = this.isShown3c = false
  this.spacingprice = 6
}
toggleShow2c() {
  this.isShown2c = true
  this.isShown1c = this.isShownc = this.isShown3c = false
  this.spacingprice = 12
}
toggleShow3c() {

  this.isShown3c = true
  this.isShown1c = this.isShown2c = this.isShownc = false
  this.spacingprice = 18

}


//aligners
toggleShowd() {
  this.isShownd = true
  this.isShown1d = this.isShown2d = this.isShown3d = false
  this.aligner = 0
}
toggleShow1d() {
  this.isShown1d = true
  this.isShownd = this.isShown2d = this.isShown3d = false
  this.aligner = 400
}

toggleShow2d() {
  this.isShown2d = true
  this.isShown1d = this.isShownd = this.isShown3d = false
  this.aligner = 1000
}

toggleShow3d() {
  this.isShown3d = true
  this.isShown1d = this.isShown2d = this.isShownd = false
  this.aligner = 600
}


//missing and baby teeth
toggleShowe() {
  this.isShowne = true
  this.isShown1e = false
  this.missingteeth = 0
}
toggleShow1e() {
  this.isShown1e = true
  this.isShowne = false
  this.missingteeth = 3
}

toggleShow2e() {
  this.isShown2e = true;
  this.isShown3e = false
  this.babyteeth = 200
}

toggleShow3e() {
  this.isShown3e = true;
  this.isShown2e =  false
  this.babyteeth = 0
}

calcprice() {
    this.fp = true
    this.time = 0
    this.finalprice = 0
    this.price = 0
    // this.finalprice = +this.price + +this.crowdingprice + +this.overbiteprice + +this.spacingprice + +this.aligner + +this.missingteeth + +this.babyteeth + +this.age


    //crowinng, spacing, no overbite
    if(this.isShownb == true){
      if(this.crowdingprice >= this.spacingprice){
          this.finalprice = this.crowdingprice + +this.missingteeth
          console.log(this.finalprice)
      }
      else{
        this.finalprice = this.spacingprice + +this.missingteeth
        console.log('spacing')

      }
    }

    // overbite price, underbite, crowding spacing
    if(this.isShown1b == true || this.isShown2b){
      if(this.crowdingprice >= this.overbiteprice && this.crowdingprice >= this.spacingprice){

        this.finalprice = +this.crowdingprice + +this.missingteeth

       }
       if(this.spacingprice >= this.overbiteprice && this.spacingprice >= this.crowdingprice){

        this.finalprice = +this.spacingprice + +this.missingteeth

       }
       if(this.overbiteprice >= this.spacingprice && this.overbiteprice >= this.crowdingprice){
        this.finalprice = +this.overbiteprice + +this.missingteeth
       }










    }
 // under price, underbite,


    // crossbite pricing with crowding
    if(this.isShown3b == true){
    //   //crossbite with minor and mild crowding and spacing minor
         if(this.crowdingprice >= this.overbiteprice && this.crowdingprice >= this.spacingprice){

          this.finalprice = +this.crowdingprice + +this.missingteeth

         }
         if(this.spacingprice >= this.overbiteprice && this.spacingprice >= this.crowdingprice){

          this.finalprice = +this.spacingprice + +this.missingteeth

         }
         if(this.overbiteprice >= this.spacingprice && this.overbiteprice >= this.crowdingprice){
          this.finalprice = +this.overbiteprice + + this.missingteeth
         }





     }

     this.time = this.finalprice*200 + +this.aligner
     this.monthly = (this.time -350)/this.finalprice
     this.carecredit = (this.time)/(48)



  //   else{
  //  this.finalprice = +this.price + +this.crowdingprice + +this.overbiteprice + +this.spacingprice + +this.aligner + +this.missingteeth + +this.babyteeth + +this.age

  //   }
    // underbite pricing
    // if(this.isShown1c == true ){
    //   this.finalprice = +this.overbiteprice + +this.missingteeth
    // }
    // // crossbite pricing
    // if(this.isShown1d == true ){
    //   this.finalprice = +this.overbiteprice + +this.missingteeth
    // }



}

ngOnInit(): void {

}





}
