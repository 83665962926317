import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-veneers',
  standalone: true,
  imports: [],
  templateUrl: './veneers.component.html',
  styleUrl: './veneers.component.scss'
})
export class VeneersComponent {


  title = 'Veneers Specialist | Corona';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Transform your smile with custom-made veneers in Corona. Our dental experts will help you enhance your teeth's appearance. Call (909) 793-1595 | Ketcherside Orthodontics" }
    );
  }

}
