import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rootcanals',
  standalone: true,
  imports: [],
  templateUrl: './rootcanals.component.html',
  styleUrl: './rootcanals.component.scss'
})
export class RootcanalsComponent {

  title = 'Root Canals in Corona';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Relieve your tooth pain with Root Canal Treatment at Ketcherside Orthodontics Inc. Call (909) 793-1595' }
    );
  }
}
