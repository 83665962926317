
import { RouterLink } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-clearbraces',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './clearbraces.component.html',
  styleUrl: './clearbraces.component.scss'
})
export class ClearbracesComponent {

  title = 'Clear Braces in Corona, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Clear Braces specialists in Corona, CA. Schedule your free consultation today!' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@context": "http://schema.org",
          "@type": "Service",
          "serviceType": "Orthodontic Treatment",
          "name": "Clear Braces",
          "description": "Clear braces offer a less noticeable, aesthetic solution for teeth straightening. Combining efficiency with a more discreet appearance, they are ideal for adults and teens who prefer a subtler orthodontic treatment.",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Ketcherside Orthodontics",
            "areaServed": {
              "@type": "City",
              "name": "Corona"
            },
            "category": "Orthodontics",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "720 Magnolia Ave Suite #1",
              "addressLocality": "Corona",
              "addressRegion": "CA",
              "postalCode": "92879"
            },
            "telephone": "+19097931595",
            "email": "drgaryk1@gmail.com",
            "image": "https://ketchersideortho.com/assets/images1/logo1.svg"
          },
          "areaServed": {
            "@type": "Place",
            "name": "Corona, CA"
          },
          "openingHours": "Mo-Fr 9:00-17:00",
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$"
        }


        `;

        this._renderer2.appendChild(this._document.body, script);

  }
}
